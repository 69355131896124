import { Button, Card, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { useTranslation } from "react-i18next";
import VipBookingForm from "../components/vpi_booking/vip_booking_form/vip_booking_form";
import {
  retrieveItem,
  send_response_to_printer_service,
} from "../../helpers/functions";
import {
  check_enrolment_session_query,
  close_enrolment_session_mutation,
  create_enrolment_session_mutation,
  dynamicClear,
  dynamicRequest,
  mutation_enrolment_ticket_print,
  query_check_has_pos,
  query_get_enrolment_detail,
  useDynamicSelector,
} from "../../services/redux";
import io from "socket.io-client";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { get_current_counter_query } from "../../services/redux/slices/graphql/graphql_counter_group";
import axios from "axios";
import { set_counter_user_mutation } from "../../services/redux/slices/graphql/graphql_enroll_booking";
import { get_enrolment_detail_query } from "../../services/redux/slices/graphql/graphql_get_enrolment_detail";
import { API_URL } from "../../helpers/constants";
import moment from "moment";
import VipBookingEnrollmentDetailModel from "../components/vpi_booking/vip_booking_enrollment_detail_model/vip_booking_enrollment_detail_model";

const VipBooking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [counter_mac_address, set_counter_mac_address] = useState(null);
  const [session_modal_visible, set_session_modal_visible] = useState(false);
  const [is_loading, set_is_loading] = useState(false);
  const [can_enroll, set_can_enroll] = useState(false);
  const [enrolled_id, set_enrolled_id] = useState(null);
  const [token_printer_response, set_token_printer_response] = useState();
  const [socket_details, set_socket_details] = useState();
  const [booking_details, set_booking_details] = useState({});
  const [enrollment_model_open, set_enrollment_model_open] = useState(false);
  const [enrollment_details, set_enrollment_details] = useState({
    booking_reference: "",
    receipt_no: "",
    amount: "",
    arrived_count: "",
    spot_vip_booking_pay_mode: "",
    district_code: "",
  });

  console.log("enrollment_details", enrollment_details);

  const [is_print_token_modal_visible, set_is_print_token_modal_visible] =
    useState(false);
  const [leader_details, set_leader_details] = useState(false);
  const [get_leader_details, set_get_leader_details] = useState("");

  let socket;
  const session_id = retrieveItem("session_id");

  const { result } = useDynamicSelector("check_has_pos");
  const { id: current_counter_id } = useDynamicSelector("get_current_counter");

  const {
    session_status: check_enrolment_session_status,
    status: check_enrolment_status,
    error: check_enrolment_session_error,
  } = useDynamicSelector("check_enrolment_session");
  const {
    id: create_enrolment_session_id,
    status: create_enrolment_session_status,
    error: create_enrolment_session_error,
  } = useDynamicSelector("create_enrolment_session");

  const { enrolment_id: check_order_status_enrolment_id } =
    useDynamicSelector("check_order_status");
  const {
    id: spot_vip_booking_id,
    booking_reference,
    receipt_no,
    amount,
    payment_mode,
    arrived_count,
    district_code,
  } = useDynamicSelector("create_spot_vip_booking");

  console.log(
    "receipt_no",
    booking_reference,
    receipt_no,
    amount,
    payment_mode,
    arrived_count,
    district_code
  );

  const { id: spot_spot_foreign_booking_id } = useDynamicSelector(
    "create_spot_foreign_booking"
  );

  const { sequence_count, booking } = useDynamicSelector(
    "get_enrolment_detail"
  );

  const {
    status: set_counter_user_status,
    id: set_counter_user_status_id,
    error: set_counter_user_error,
  } = useDynamicSelector("set_counter_user");
  const {
    status: close_enrolment_session_status,
    loading: close_enrolment_session_loading,
  } = useDynamicSelector("close_enrolment_session");

  const [has_pos, set_has_pos] = useState(false);

  const check_has_pos_query = () => {
    let key = [{ key: "check_has_pos", loading: true }];
    let query = query_check_has_pos;
    let variables = {
      data: {
        counter_id: current_counter_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_current_counter_name = () => {
    let key = [{ key: "get_current_counter", loading: true }];
    let query = get_current_counter_query;
    let variables = {
      data: {
        system_reference_id: counter_mac_address,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const set_counter_for_user = (mac_address) => {
    let keys = [{ key: "set_counter_user", loading: true }];
    let query = set_counter_user_mutation;
    let variables = {
      id: mac_address,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const TicketsPrinted = async () => {
    let key = [{ key: "set_enrolment_ticket_printed", loading: true }];
    let query = mutation_enrolment_ticket_print;
    let variables = {
      id: enrolled_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:7878/my-address");
        set_counter_mac_address(response?.data);
        console.error("response", response);
      } catch (error) {
        console.error("Error fetching MAC address:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (set_counter_user_status === "success") {
      check_enrollment_session_status(counter_mac_address);
      dispatch(dynamicClear("set_counter_user"));
    } else if (set_counter_user_error) {
      toast.error(set_counter_user_error?.message);
      dispatch(dynamicClear("set_counter_user"));
    }
  }, [set_counter_user_status, set_counter_user_error]);

  useEffect(() => {
    if (close_enrolment_session_status === "success") {
      close_session_modal();
    }
  }, [close_enrolment_session_status]);

  useEffect(() => {
    if (current_counter_id) {
      check_has_pos_query();
    }
  }, [current_counter_id]);

  useEffect(() => {
    if (counter_mac_address) {
      get_current_counter_name();
    }
  }, [counter_mac_address]);

  useEffect(() => {
    set_has_pos(result);
  }, [result]);

  useEffect(() => {
    if (token_printer_response === "printing started") {
      TicketsPrinted();
      console.log("token_printer_response", token_printer_response);
      set_is_loading(false);
      close_token_printer_modal();
      set_token_printer_response("");
      dispatch(dynamicClear("check_order_status"));
      dispatch(dynamicClear("create_spot_vip_booking"));
      dispatch(dynamicClear("create_spot_foreign_booking"));
    } else if (
      token_printer_response === "tickets already printed" ||
      token_printer_response === "invalid enrolment status" ||
      token_printer_response === "error"
    ) {
      set_is_loading(false);
      toast.error("Tickets already printed");
      close_token_printer_modal();
      set_token_printer_response("");
      dispatch(dynamicClear("check_order_status"));
      dispatch(dynamicClear("create_spot_vip_booking"));
      dispatch(dynamicClear("create_spot_foreign_booking"));
    }
  }, [token_printer_response]);

  useEffect(() => {
    if (check_order_status_enrolment_id) {
      set_enrolled_id(check_order_status_enrolment_id);
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (spot_vip_booking_id) {
      set_enrolled_id(spot_vip_booking_id);
    } else if (spot_spot_foreign_booking_id) {
      set_enrolled_id(spot_spot_foreign_booking_id);
    }
  }, [
    check_order_status_enrolment_id,
    spot_vip_booking_id,
    spot_spot_foreign_booking_id,
  ]);

  useEffect(() => {
    if (counter_mac_address) {
      set_counter_for_user(counter_mac_address);
    }
  }, [counter_mac_address]);

  useEffect(() => {
    if (check_enrolment_session_error) {
      toast.error(check_enrolment_session_error?.message);
      set_can_enroll(false);
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_enrolment_session_status === "open") {
      close_session_modal();
      set_can_enroll(true);
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_enrolment_session_status === "closed") {
      set_can_enroll(false);
      open_session_modal();
      dispatch(dynamicClear("check_enrolment_session"));
    }
  }, [check_enrolment_session_status, check_enrolment_session_error]);

  useEffect(() => {
    if (create_enrolment_session_status === "success") {
      set_can_enroll(true);
      close_session_modal();
      dispatch(dynamicClear("create_enrolment_session"));
    } else if (create_enrolment_session_error) {
      set_can_enroll(false);
      toast.error(create_enrolment_session_error?.message);
      dispatch(dynamicClear("create_enrolment_session"));
    }
  }, [
    create_enrolment_session_status,
    create_enrolment_session_id,
    create_enrolment_session_error,
  ]);
  const open_token_printer_modal = () => {
    set_is_print_token_modal_visible(true);
  };
  const close_token_printer_modal = (val) => {
    console.log("qq");
    set_is_print_token_modal_visible(false);
  };

  const open_session_modal = () => {
    set_session_modal_visible(true);
  };
  const close_session_modal = () => {
    set_session_modal_visible(false);
  };
  const check_enrollment_session_status = (mac_address) => {
    let keys = [{ key: "check_enrolment_session", loading: true }];
    let query = check_enrolment_session_query;
    let variables = {
      data: {
        system_reference_id: mac_address,
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  // useEffect(() => {
  //   get_enrolment_details();
  // }, [spot_vip_booking_id]);

  // const get_enrolment_details = () => {
  //   let keys = [{ key: "get_enrolment_detail", loading: true }];
  //   let query = query_get_enrolment_detail;
  //   let variables = {
  //     id: spot_vip_booking_id,
  //   };
  //   dispatch(dynamicRequest(keys, query, variables));
  // };

  const create_enrollment_session_status = () => {
    let keys = [{ key: "create_enrolment_session", loading: true }];
    let query = create_enrolment_session_mutation;
    let variables = null;
    dispatch(dynamicRequest(keys, query, variables));
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("VIP_BOOKING")} />
        </Row>
      </Row>
      {can_enroll && (
        <Card
          style={{
            width: "98%",
            margin: "5px auto",
            minHeight: 0,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <VipBookingForm
            open_token_printer_modal={open_token_printer_modal}
            has_pos={has_pos}
            leader_details={leader_details}
            set_leader_details={set_leader_details}
            get_leader_details={get_leader_details}
            set_get_leader_details={set_get_leader_details}
            set_booking_details={set_booking_details}
            booking_details={booking_details}
            set_enrollment_details={set_enrollment_details}
          />
        </Card>
      )}
      {!can_enroll && (
        <Card
          style={{
            // height: "70%",
            height: "auto",
            overflowY: "scroll",
            margin: "10px",
            width: "97%",
            backgroundColor: "white",
            marginLeft: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginLeft: "35%",
            }}
          >
            Checking Counter Status...
          </Typography>
        </Card>
      )}
      <Modal
        title="Create Session"
        open={session_modal_visible}
        style={{
          width: "100%",
          height: "50%",
        }}
        onCancel={close_session_modal}
        maskClosable={true}
        footer={null}
        closeIcon={<></>}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>
            Currently, you don't have an active session. Create a new enrollment
            session
          </p>
          <div>
            <Button
              type="primary"
              onClick={() => {
                create_enrollment_session_status();
              }}
            >
              {"Start New Session"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={"Print Token"}
        open={is_print_token_modal_visible}
        onCancel={() => {
          // close_token_printer_modal();
        }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={() => {
              close_token_printer_modal();
            }}
          >
            {"No"}
          </Button>,
          <Button
            loading={is_loading}
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={() => {
              send_response_to_printer_service(
                enrolled_id,
                session_id,
                set_is_loading,
                TicketsPrinted,
                close_token_printer_modal,
                set_token_printer_response
              );
              set_enrollment_model_open(true);
            }}
          >
            {"Yes"}
          </Button>,
          ,
        ]}
      >
        <Typography>
          {
            "Your information saved successfully..! Do you want to print the receipt ?"
          }
        </Typography>
      </Modal>

      <VipBookingEnrollmentDetailModel
        enrollment_model_open={enrollment_model_open}
        set_enrollment_model_open={set_enrollment_model_open}
        enrollment_details={enrollment_details}
      />
    </div>
  );
};

export default VipBooking;
