import { Button } from "antd";
import { Box } from "native-base";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";

const BookingLoginButton = () => {
  const handle_login = () => {};
  const { loading: login_loading } = useDynamicSelector("login");
  return (
    <div>
      <Button
        style={{
          backgroundColor: "#FE301D",
          color: "white",
          width: "300px",
          border: "transparent",
        }}
        onClick={handle_login}
        key="confirm"
        htmlType="submit"
        form="login_form"
        loading={login_loading}
      >
        Login
      </Button>
    </div>
  );
};

export default BookingLoginButton;
