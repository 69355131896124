import { Col, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  mutation_booking_cancel,
  query_booking_history_list,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { FaPrint } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { Pressable } from "native-base";
import toast from "react-hot-toast";
import moment from "moment";
import BookingHistoryCancelBookingModal from "./booking_history_cancel_booking_modal/booking_history_cancel_booking_modal";
import { startCase } from "lodash";
import { retrieveItem } from "../../../helpers/functions";

const BookingHistoryList = ({ mandram_name, active_mandram_keys }) => {
  const [open_notification, set_open_notification] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [cancel_booking_details, set_cancel_booking_details] = useState();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const role = retrieveItem("role");
  const {
    items: booking_list,
    loading: booking_list_loading,
    pagination: get_details_pagination,
  } = useDynamicSelector("get_booking_list");

  const { status: cancel_status, error: cancel_error } =
    useDynamicSelector("cancel_booking");

  let filter = {
    date_slot_id: mandram_name?.date_slot_id,
    mandram_id: mandram_name?.mandram_id,
  };

  const mandram_wise_booking_list = (filter) => {
    let key = [{ key: "get_booking_list", loading: true }];
    let query = query_booking_history_list;
    let variables = {
      filter,
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  // useEffect(() => {
  //   fetch_data();
  // }, [page_number, page_limit]);
  const mandram_booking_cancel = (id) => {
    let key = [{ key: "cancel_booking", loading: true }];
    let query = mutation_booking_cancel;
    let variables = {
      id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetch_data = (page_number, page_limit) => {
    let filter = {
      date_slot_id: mandram_name?.date_slot_id,
      mandram_id: mandram_name?.mandram_id,
    };
    let key = [{ key: "get_booking_list", loading: true }];
    let query = query_booking_history_list;

    let variables = {
      filter,
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
    set_page_limit(page_limit);
    set_page_number(page_number);
  };

  useEffect(() => {
    if (cancel_status === "success") {
      toast.success("Booking cancel successfully");
      mandram_wise_booking_list(filter);
      dispatch(dynamicClear("cancel_booking"));
    } else if (cancel_error) {
      toast.error(cancel_error?.message);
      dispatch(dynamicClear("cancel_booking"));
    }
  }, [cancel_status, cancel_error]);

  useEffect(() => {
    mandram_wise_booking_list(filter);
  }, [mandram_name, active_mandram_keys]);

  const handle_booking_receipt = (booking_details) => {
    const url = `/print-receipt/${booking_details?.booking_reference}/${booking_details?.booking_user?.mobile}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }
  };
  const handle_mandram_close = (booking_details) => {
    set_cancel_booking_details(booking_details);
    set_open_notification(true);
    // mandram_booking_cancel(booking_details?.id);
  };

  const BookingAction = ({ booking_details, cancelled_count }) => {
    return (
      <div className="booking-action">
        {cancelled_count > 0 ? null : (
          <Col style={{ display: "flex", justifyContent: "space-between" }}>
            <Pressable
              onPress={() => handle_booking_receipt(booking_details)}
              style={{ marginRight: "15px" }}
            >
              <FaPrint size={20} color="#3b90f5" />
            </Pressable>

            {role != "District User" && (
              <Pressable onPress={() => handle_mandram_close(booking_details)}>
                <TiCancel size={25} color="#f90000" />
              </Pressable>
            )}
          </Col>
        )}
      </div>
    );
  };

  let columns = [
    {
      title: t("booking_number"),
      dataIndex: "booking_reference",
      key: "booking_reference",
      fixed: "left",
      width: 150,
    },

    // {
    //   title: t("abishekam_date"),
    //   dataIndex: "date_slot",
    //   render: (dateSlot) => {
    //     const formatted_date = dateSlot?.date
    //       ? moment(dateSlot.date).format("DD/MM/YYYY")
    //       : "";
    //     return formatted_date || "-";
    //   },
    // },

    {
      title: t("booked_counts"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        console.log("rrr", record);
        let result = record?.count;
        return result;
      },
    },

    {
      title: t("cancelled"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return record?.cancelled_count;
      },
    },
    {
      title: t("enrolled"),
      dataIndex: "",
      key: "",
      align: "center",

      render: (record) => {
        const total_arrived_count = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        return total_arrived_count || 0;
      },
    },

    {
      title: t("yet_to_enroll"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        let yet_to_enroll = 0;
        let total_enrolled = record?.enrolment_list?.reduce((acc, booking) => {
          return acc + booking.arrived_count;
        }, 0);
        yet_to_enroll = record?.count - total_enrolled;
        if (yet_to_enroll < 0) {
          yet_to_enroll = 0;
          return yet_to_enroll;
        } else if (record?.cancelled_count > 0) {
          return 0;
        } else {
          return yet_to_enroll;
        }
      },
    },
    {
      title: t("excess"),
      dataIndex: "",
      key: "address",
      align: "center",
      render: (record) => {
        const total_enrolled = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        let excess = 0;
        excess = record?.count - total_enrolled;
        if (excess < 0) {
          return excess * -1;
        }
        return 0;
      },
    },
    {
      title: t("group_leader_name"),
      dataIndex: "",
      key: "",
      align: "center",

      render: (record) => {
        return record?.booking_user?.name || "-";
      },
    },
    {
      title: t("leader_no"),
      dataIndex: "",
      key: "",
      align: "center",

      render: (record) => {
        return record?.booking_user?.mobile;
      },
    },
    {
      title: t("booked_by"),
      dataIndex: "",
      key: "",
      align: "center",

      render: (record) => {
        return startCase(record?.booked_by?.name);
      },
    },
    // {
    //   title: t("booked_date"),
    //   dataIndex: "booked_on",
    //   key: "booked_on",
    //   align: "center",

    //   render: (booked_date) => {
    //     const booking_date = moment(booked_date).format("DD/MM/YYYY");
    //     return booking_date;
    //   },
    // },
    {
      title: t("payment_mode"),
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        const paymentMode = startCase(record?.payment?.payment_mode);
        return paymentMode.toUpperCase();
      },
    },
    {
      title: t("payment_status"),
      dataIndex: "",
      key: "",
      key: "",
      align: "center",

      render: (record) => {
        return record?.enrolment_list?.status
          ? record?.enrolment_list?.status
          : "-";
      },
    },
    {
      title: t("status"),
      dataIndex: "",
      key: "",
      align: "center",

      render: (record) => {
        return startCase(record?.booking_status);
      },
      fixed: "right",
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "",
      fixed: "right",
      render: (record) => {
        return (
          <BookingAction
            booking_details={record}
            cancelled_count={record?.cancelled_count}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={booking_list}
        loading={booking_list_loading}
        pagination={false}
        scroll={{
          x: 1900,
        }}
      />
      <Pagination
        style={{ marginTop: "5px" }}
        total={get_details_pagination?.total_count}
        showSizeChanger={true}
        page_number={page_number}
        hideOnSinglePage={false}
        onChange={fetch_data}
      />
      <BookingHistoryCancelBookingModal
        open_notification={open_notification}
        set_open_notification={set_open_notification}
        set_modal_open={set_modal_open}
        mandram_booking_cancel={mandram_booking_cancel}
        cancel_booking_details={cancel_booking_details}
      />
    </div>
  );
};

export default BookingHistoryList;
