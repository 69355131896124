import { Col, Skeleton, Typography } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../services/redux";

const Count = ({ label, value, hide_border, backgroundColor }) => {
  const { loading: booking_summary_loading } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );

  return (
    <Col
      style={{
        width: "20%",
        height: "100%",
        fontSize: "15px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // borderRight:hide_border?"0px": "1px solid white",
        backgroundColor: backgroundColor,
      }}
    >
      <Typography style={{ textAlign: "center", color: "white" }}>
        {label}
      </Typography>
      <Typography style={{ color: "white", fontWeight: "bold" }}>
        {booking_summary_loading && (
          <Skeleton.Button active={true} size={"small"} shape={"rounded"} />
        )}
        {!booking_summary_loading && value}
      </Typography>
    </Col>
  );
};

export default Count;
