import { Card, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AbishekamDateFormItem from "./report_abishekam_date_form_item";
import DistrictFormItem from "./report_district_form_item";
import MandramFormItem from "./report_mandarm_form_item";
import DateWiseReportSearchButton from "../report_search_button_form_item";
import {
  dynamicClear,
  dynamicRequest,
  get_district_list,
  get_mandram_list,
  query_get_date_district_mandram_wise_summary,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import DateWiseReportCollapseTable from "../date_wise_report_collapse/date_wise_report_collapse_table";
import DateWiseReportAbishekam from "./report_abishekam_date_form_item";

const DateWiseReportMainForm = (props) => {
  const dispatch = useDispatch();
  const {
    get_date_wise_report_query,
    selected_district,
    set_selected_district,
    selected_date,
    set_selected_date,
    set_selected_mandram,
    set_search_button,
    set_abishekam_date_id,
    set_selected_mandram_id,
    set_selected_items,
    set_selected_abishekam_date,
    get_date_district_mandram_wise_summary,
    get_date_wise_summary_print,
    get_date_wise_count_print,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const [selected_district, set_selected_district] = useState("");
  const [mandram_item, set_mandram_item] = useState([]);
  const [selected_dates, set_selected_dates] = useState([]);
  const [intial_date, set_initial_date] = useState();
  const [selected_options, set_selected_options] = useState([]);
  const [district_id, set_district_id] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [table_open, set_table_open] = useState(false);
  const [mandram_id, set_mandram_id] = useState();
  const [initial_values, set_initial_values] = useState({});
  const [booking_detail, set_booking_detail] = useState();
  const [selected_district_id, set_selected_district_id] = useState("");
  const [dates, set_dates] = useState();

  const { items: date_wise_report_district_items } = useDynamicSelector(
    "date_wise_report_district_list"
  );

  const { items: date_wise_report_mandram_items } = useDynamicSelector(
    "date_wise_report_mandram_list"
  );

  const { items: get_date_district_mandram_wise_summary_list } =
    useDynamicSelector("get_date_district_mandram_wise_summary");

  useEffect(() => {
    dispatch(dynamicClear("get_date_district_mandram_wise_summary"));

    get_district();
  }, []);

  useEffect(() => {
    get_mandram();
  }, [selected_district_id]);

  // useEffect(() => {
  //   if (edit_values.id) {
  //     form.setFieldsValue({
  //       ...edit_values,
  //     });
  //     // set_initial_date({ ...edit_values });
  //   } else {
  //     form.resetFields();
  //   }
  // }, [edit_values]);

  // const get_date_district_mandram_wise_summary = () => {
  //   let key = [
  //     { key: "get_date_district_mandram_wise_summary", loading: true },
  //   ];
  //   let query = query_get_date_district_mandram_wise_summary;
  //   let district_id_lists = [district_id];
  //   let variables = {
  //     filter: {
  //       date_slot_id_list: selected_date,
  //       district_id_list: district_id ? district_id_lists : [],
  //       mandram_id_list: mandram_item,
  //     },
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  const get_district = () => {
    let key = [{ key: "date_wise_report_district_list", loading: false }];
    let query = get_district_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_mandram = () => {
    let key = [{ key: "date_wise_report_mandram_list", loading: false }];
    let query = get_mandram_list;

    let variables = {
      filter: {
        district_id: selected_district_id,
      },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  const select_all_dates = (values) => {
    const allDates = date_wise_report_items?.map((h) => h.id);
    set_selected_dates(allDates);
  };

  const on_finish = (values) => {
    get_date_district_mandram_wise_summary(values);
    get_date_wise_summary_print();
    get_date_wise_count_print();
  };

  const on_change_values = (value, values) => {
    dispatch(dynamicClear("get_date_district_mandram_wise_summary"));
    set_selected_district_id(value?.district);
    set_selected_items(values);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Form
        onFinish={on_finish}
        id="date_wise_search"
        form={form}
        // initialValues={intial_date}
        onValuesChange={on_change_values}
        style={{ width: "100%" }}
      >
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            backgroundColor: "#e7e7e7",
            margin: "auto",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Row gutter={24} style={{ height: "30px" }}>
            <Col span={11}>
              <DateWiseReportAbishekam
                set_selected_date={set_selected_date}
                set_dates={set_dates}
                set_abishekam_date_id={set_abishekam_date_id}
              />
            </Col>
            <Col span={10}>
              <DistrictFormItem set_district_id={set_district_id} />
            </Col>
            <Col span={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "50px",
                }}
              >
                <DateWiseReportSearchButton
                  set_search_button={set_search_button}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={24} style={{ height: "30px", marginTop: "20px" }}>
            <Col span={11}>
              <MandramFormItem set_mandram_item={set_mandram_item} />
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default DateWiseReportMainForm;
