import { Box, Text } from "native-base";
import React, { useEffect } from "react";
import IrumudiAndBookingPortalForm from "./booking_portal_form/booking_portal_form";
import IrumudiPasswordFormItem from "./booking_portal_form/booking_portal_password_form_item";
import { Col, Row, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const BookingPortal = () => {
  const { t } = useTranslation();
  return (
    <>
      <Col>
        <Row
          style={{
            backgroundColor: "white",
            height: "15%",
            width: "100%",
            marginLeft: "50px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            backgroundColor: "#FFDE8B",
            // borderTopLeftRadius: "10px",
            // borderTopRightRadius: "10px",

            fontSize: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                width: "100%",
                color: "",
                marginTop: "15px",
                fontWeight: "bold",
                fontSize: "15px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Booking Portal
            </Typography>
          </div>
        </Row>
        <Row
          style={{
            width: "100%",
            backgroundColor: "#FFDE8B",
            padding: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50px",
            borderRadius: "0 0 10px 10px",
            // borderBottomLeftRadius: "10px",
            // borderBottomRightRadius: "10px",

            // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <IrumudiAndBookingPortalForm />
        </Row>
      </Col>
    </>
  );
};

export default BookingPortal;
