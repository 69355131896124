import React, { useEffect, useState } from "react";
import { Card, Row, Table, Typography } from "antd";
import BookingHistoryForm from "../components/booking_history/booking_history_form/booking_history_form";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../services/redux/selector";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_get_booking_summary_dashboard,
} from "../../services/redux/slices";
import { district_details_query } from "../../services/redux/slices/graphql/graphql_district";
import { retrieveItem } from "../../helpers/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import CustomPageTitle from "../components/common/custom_page_title";

const BookingHistory = ({ date }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [table_open, set_table_open] = useState(false);
  const [mandram_id, set_mandram_id] = useState();
  const [initial_values, set_initial_values] = useState({});
  const [booking_detail, set_booking_detail] = useState();
  const [mandram_item, set_mandram_item] = useState([]);
  console.log("mandram_item", mandram_item);
  const [selected_options, set_selected_options] = useState([]);
  const [district_id, set_district_id] = useState();
  const dispatch = useDispatch();
  const role = retrieveItem("role");

  const system_user = retrieveItem("system_user");

  const item = useDynamicSelector("get_booking_detail");
  const { items: booking_summary } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );

  const booking_summary_dashboard = useDynamicSelector(
    "get_booking_summary_dashboard"
  );

  useEffect(() => {
    // booking_summary_dashboard_count();
    get_district_list();
  }, []);

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  // const booking_summary_dashboard_count = () => {
  //   let key = [{ key: "get_booking_summary_dashboard", loading: true }];
  //   let query = query_get_booking_summary_dashboard;
  //   let district_id_lists = [district_id];
  //   let variables = {
  //     filter: {
  //       date_slot_id_list: selected_options,
  //       district_id_list: district_id ? district_id_lists : [],
  //       mandram_id_list: mandram_item,
  //     },
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <div style={{ marginLeft: "18px" }}>
            <CustomPageTitle title={t("booking_history_title")} />
          </div>
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Booking History"} /> */}
        </Row>
      </Row>
      {/* <Typography
        style={{
          fontSize: "20px",
          marginLeft: "20px",
          fontWeight: "bold",
          marginTop: "15px",
        }}
      >
        {t("booking_history_title")}
      </Typography> */}

      <Card
        style={{
          width: "96%",
          margin: "auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <BookingHistoryForm
          search_abishekam_date={location.props_state?.id}
          set_table_open={set_table_open}
          set_mandram_id={set_mandram_id}
          initial_values={initial_values}
          set_initial_values={set_initial_values}
          set_booking_detail={set_booking_detail}
          mandram_id={mandram_id}
          role={role}
          system_user={system_user}
          booking_detail={booking_detail}
          table_open={table_open}
          mandram_item={mandram_item}
          set_mandram_item={set_mandram_item}
          set_selected_options={set_selected_options}
          selected_options={selected_options}
          set_district_id={set_district_id}
          district_id={district_id}
        />
      </Card>
    </>
  );
};
export default BookingHistory;
