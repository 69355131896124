import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { retrieveItem } from "../../../../../helpers/functions";

const MandramIdInput = (props) => {
  const { header_type, set_mandram_code, form } = props;
  const role = retrieveItem("role");
  const [input_value, set_input_value] = useState("");

  const handle_change = (e) => {
    const upper_case_value = e.target.value.toUpperCase();
    // props.set_mandram_code(upper_case_value);
    set_input_value(upper_case_value);
  };
  useEffect(() => {
    form.setFieldValue("code", input_value);
  }, [input_value]);
  return (
    <Form.Item
      label="Code"
      name="code"
      rules={[
        {
          required: true,
          message: "Please enter Mandram Code",
        },
        {
          pattern: /^[a-zA-Z]{1,10}-\d{1,10}$/,
          message: "Please enter a valid code format (XX-12)",
        },
      ]}
    >
      <Input
        onChange={handle_change}
        value={input_value}
        disabled={
          role === "Super Admin" ||
          role === "MASM Admin" ||
          role === "MASM User" ||
          (role === "District Admin" && header_type === "Add Mandram")
            ? false
            : true
        }
      />
    </Form.Item>
  );
};

export default MandramIdInput;
