import React, { useRef, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import AddressReportMainForm from "./address_report_main_form/address_report_main_form";
import { FileExcelOutlined } from "@ant-design/icons";
import AddressReportListItems from "./address_report_list/address_report_list_items";
import AddressWiseReportPrint from "./address_report_main_form/address_report_print/address_report_print";
import { useDynamicSelector } from "../../../../services/redux";
import { capitalize } from "lodash";
import { export_to_xlsx } from "../../../../helpers/functions";
import { useReactToPrint } from "react-to-print";

const AddressReportMainComponent = (props) => {
  const {
    address_report,
    set_selected_mandram,
    set_district_name,
    set_selected_date,
  } = props;
  const { t } = useTranslation();

  const { items: address_list_items } = useDynamicSelector(
    "get_address_report_summary"
  );

  // const print_district_wise_report = () => {
  //   const printWindow = window.open("", "", "width=600,height=600");
  //   const address_wise_print_html_content = ` <html>
  //   <html>
  //   <head>
  //     <style>
  //       body {
  //         font-family: Arial, sans-serif;
  //         margin: 20px;
  //       }
  //       h3 {
  //         text-align: center;
  //         color: #333;
  //       }
  //       table {
  //         width: 100%;
  //         border-collapse: collapse;
  //         margin-top: 20px;
  //       }
  //       th, td {
  //         border: 1px solid #ddd;
  //         padding: 8px;
  //         text-align: center;
  //       }
  //     </style>
  //   </head>
  //   <body>
  //     ${document.getElementById("address-wise-report").innerHTML}
  //   </body>
  // </html>
  //   `;
  //   printWindow.document.write(address_wise_print_html_content);
  //   printWindow.document.close();

  //   setTimeout(() => {
  //     printWindow.print();
  //     printWindow.close();
  //   }, 1000);
  // };

  return (
    <div>
      <div>
        <AddressReportMainForm
          address_report={address_report}
          set_selected_mandram={set_selected_mandram}
          set_district_name={set_district_name}
          set_selected_date={set_selected_date}
        />
      </div>

      {/* <div style={{ display: "none" }} id="address-wise-report">
        <AddressWiseReportPrint district_name={district_name} />
      </div> */}
    </div>
  );
};

export default AddressReportMainComponent;
