import { Form, Input, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";

const IrumudiCount = ({ count, set_count, set_amount }) => {
  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");

  const ticket_price = season_date_slot_list_items?.[0]?.season?.ticket_price;
  const { t } = useTranslation();
  const on_change = (val) => {
    set_count(val);
  };
  useEffect(() => {
    set_amount(count * ticket_price);
  }, [count]);

  return (
    <Form.Item
      label={t("irumudi_count")}
      name="irumudi_count"
      rules={[
        {
          validator: (_, value) => {
            if (value < 0) {
              return Promise.reject("Negative numbers are not allowed!");
            } else if (value === 0) {
              return Promise.reject("Zero is not allowed!");
            } else {
              return Promise.resolve();
            }
          },
          required: true,
        },
      ]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        placeholder="Enter the irumudi count"
        onChange={on_change}
      />
    </Form.Item>
  );
};

export default IrumudiCount;
