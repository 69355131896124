import { Card, Col, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import DistrictDashboardCount from "../components/district_dashboard/district_dashbord_count/district_dashbord_count";
import MandramWiseCount from "../components/district_dashboard/mandram_wise_count/mandram_wise_count";
import { object } from "prop-types";
import { query_calendar_dashboard } from "../../services/redux/slices/graphql/graphql_irumudi_calendar";
import {
  dynamicRequest,
  query_district_dashboard,
  query_season_district_summary,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import DistrictDashboardLastCurrentYearCount from "../components/district_dashboard/district_dashbord_count/district_dashboard_last_current_year_count";
import {
  ChartLabel,
  Hint,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { Chart } from "react-google-charts";
import colors from "../../helpers/colors";

const DistrictDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: mandram_count, loading: mandram_count_loading } =
    useDynamicSelector("get_district_admin_dashboard_mandram_count_summary");

  const { last_season_enrolled_count, current_season_enrolled_count } =
    useDynamicSelector("get_season_district_summary");

  const calculateTotals = () => {
    let totalBooked = 0;
    let totalArrived = 0;
    let totalNotArrived = 0;
    let totalExcess = 0;
    let totalCancelled = 0;

    mandram_count?.forEach((mandram) => {
      totalBooked += mandram.count.booked;
      totalArrived += mandram.count.arrived;
      totalNotArrived += mandram.count.yet_to_enroll;
      totalExcess += mandram.count.excess;
      totalCancelled += mandram.count.cancelled;
    });

    return {
      totalBooked,
      totalArrived,
      totalNotArrived,
      totalExcess,
      totalCancelled,
    };
  };

  const {
    totalBooked,
    totalArrived,
    totalNotArrived,
    totalExcess,
    totalCancelled,
  } = calculateTotals();

  const get_district_wise_mandram_dashboard = () => {
    let keys = [
      {
        key: "get_district_admin_dashboard_mandram_count_summary",
        loading: true,
      },
    ];
    let query = query_district_dashboard;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  const get_season_district_summary_list = () => {
    let keys = [
      {
        key: "get_season_district_summary",
        loading: true,
      },
    ];
    let query = query_season_district_summary;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_district_wise_mandram_dashboard();
    get_season_district_summary_list();
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    let _data = [
      ["Year", "Count", { role: "style" }],
      ["Last Year", last_season_enrolled_count, "#238E94"],
      ["Current Year", totalArrived, "#4F9F49"],
    ];
    setData(_data);
  }, [last_season_enrolled_count, totalArrived]);

  const options = {
    title: "Comparison of Last Year and Current Year Enrolled",
    chartArea: { width: "40%" },
    vAxis: {
      title: "Count",
      minValue: 0,
    },
    hAxis: {
      title: "Year",
    },
    bars: "vertical",
    legend: { position: "none" },
    colors: ["#238E94", "#4F9F49"],
    series: {
      0: { color: "#238E94" },
      1: { color: "#4F9F49" },
    },
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("district_dashboard_title")} />
        </Row>
      </Row>

      <Row
        style={{
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      >
        <DistrictDashboardLastCurrentYearCount
          booked={totalBooked}
          arrived={totalArrived}
          not_arrived={totalNotArrived}
          excess={totalExcess}
          cancelled={totalCancelled}
        />
      </Row>
      <div
        style={{
          width: "100%",
          display: "flex",
          marginLeft: "10px",
        }}
      >
        <Card
          style={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            overflowY: "hidden",
            width: "60%",
            height: "100%",
          }}
        >
          <MandramWiseCount />
        </Card>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40%",
          }}
        >
          <Chart
            chartType="ColumnChart"
            data={data}
            options={options}
            width="100%"
            height="400px"
            legendToggle
          />
        </div>

        {/* <DistrictDashboardCount
          booked={totalBooked}
          arrived={totalArrived}
          not_arrived={totalNotArrived}
        /> */}
      </div>
    </div>
  );
};

export default DistrictDashboard;
