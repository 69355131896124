import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import BookingPortal from "./booking_portal";
import IrumudiPortal from "./irumudi_portal";
import IrumudiLoginButton from "./booking_portal_form/booking_login_button";
import { Radio, Row, Segmented, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  get_active_season_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { format_date } from "@fullcalendar/react";
import moment from "moment";

const BookingAndIrumudiPortal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show_booking_portal, set_booking_portal] = useState(true);
  const [show_irumudi_receipt, set_irumudi_receipt] = useState(false);
  const [clicked_button_type, setClicked_button_type] =
    useState("booking_portal");

  const [options, setOptions] = useState(["Booking Portal", "Irumudi receipt"]);
  const [show_booking_portal_segmented, set_booking_portal_segmented] =
    useState(true);
  const [show_irumudi_receipt_segmented, set_irumudi_receipt_segmented] =
    useState(false);

  const [selected_option, set_selected_option] = useState("Booking Portal");
  const {
    start_date: season_active_start_date,
    end_date: season_active_end_date,
  } = useDynamicSelector("get_active_season");

  useEffect(() => {
    active_season();
  }, []);

  const active_season = () => {
    let key = [{ key: "get_active_season", loading: false }];
    let query = get_active_season_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_booking_portal = () => {
    set_booking_portal(true);
    set_irumudi_receipt(false);
    setClicked_button_type("booking_portal");
  };
  const handle_irumudi_receipt = () => {
    set_irumudi_receipt(true);
    format_date;
    set_booking_portal(false);
    setClicked_button_type("irumudi_receipt");
  };
  const handle_segmented_change = (value) => {
    set_selected_option(value);
    dispatch(dynamicClear("get_booking_receipt"));
  };

  function format_date(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  function formatDate(date) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);

    const parts = formattedDate.split("-");
    if (parts.length === 3) {
      parts[1] = parts[1].toUpperCase();
    }

    return parts.join("-");
  }
  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Text style={{ fontSize: "15px" }}>
          Welcome To Irumudi Booking System
        </Text>
        <Text style={{ color: "#bd0000", fontSize: "12px", marginTop: "10px" }}>
          {season_active_start_date && season_active_end_date
            ? `Date: ${moment(season_active_start_date).format(
                "DD-MMM-YYYY"
              )} To ${moment(season_active_end_date).format("DD-MMM-YYYY")}`
            : null}
          {/* {season_active_start_date && season_active_end_date
            ? `Date: ${formatDate(
                new Date(season_active_start_date)
              )} To ${formatDate(new Date(season_active_end_date))}`
            : null} */}
        </Text>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Row space="10" justifyContent={"center"}>
          <Segmented
            className="login-menu"
            options={["Booking Portal", "Irumudi Receipt"]}
            selected={selected_option}
            onChange={handle_segmented_change}
            style={{
              width: "100%",
              backgroundColor:
                selected_option === "Booking Portal" ||
                selected_option === "Irumudi Receipt"
                  ? "#fe331f"
                  : "fe331f",
              color:
                selected_option === "Booking Portal" ||
                selected_option === "Irumudi Receipt"
                  ? "white"
                  : "white",
              fontWeight:
                selected_option === "Booking Portal" ||
                selected_option === "Irumudi Receipt"
                  ? "bold"
                  : "normal",
              fontSize: "14px",
            }}
          ></Segmented>
        </Row>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "102px",
          marginTop: "15px",

          // width: "100%",
        }}
      >
        {selected_option === "Booking Portal" && <BookingPortal />}
        {selected_option === "Irumudi Receipt" && <IrumudiPortal />}
      </div>
    </Box>
  );
};

export default BookingAndIrumudiPortal;
