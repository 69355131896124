import { Button, Form } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const BookingHistorySubmit = ({
  search_abishekam_date,
  set_table_open,
  on_finish,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    on_finish();
    set_table_open(true);
  };

  // useEffect(() => {
  //   handleClick();
  // }, [search_abishekam_date]);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Form.Item>
        <Button
          htmlType="button"
          style={{
            borderRadius: "5px",
            backgroundColor: "#025A80",
            color: "white",
            width: "150px",
          }}
          onClick={handleClick}
        >
          {t("get_details")}
        </Button>
      </Form.Item>
    </div>
  );
};

export default BookingHistorySubmit;
