import { Button, Card, Col, Form, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SpotBookingAbishekam from "./spot_booking_abishekam";
import SpotBookingDistrict from "./spot_booking_district";
import SpotBookingMandram from "./spot_booking_mandram_vsg";
import SpotBookingInchargeName from "./spot_booking_incharge_name";
import SpotBookingDevoteesCount from "./spot_booking_devotees_count";
import SpotBookingName from "./spot_booking_name";
import SpotBookingAddress from "./spot_booking_address";
import SpotBookingSubmit from "./spot_booking_submit";
import SpotBookingInchargeContactNo from "./spot_booking_incharge_contact_no";
import SpotBookingInchargeMobileNo from "./spot_booking_incharge_mobile_no";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
  mutation_create_spot_booking,
  query_get_booking_details,
  query_get_booking_user_details,
  query_get_mandram_list,
  query_get_season_date_slot_list,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import IdNumber from "../../booking/booking_form/booking_id_number";
import SpotBookingLeaderDetails from "./spot_booking_incharge_leader_details";
import moment from "moment";
import SpotBookingLeaderDetailsFetch from "./spot_booking_leader_details/spot_booking_leader_details";
import { toast } from "react-toastify";
import BookingPayment from "../../booking/booking_form/booking_radio_button";
import { retrieveItem } from "@helpers/functions";
import PaymentModeInput from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/payment_mode_input";
import {
  check_order_status_for_spot_booking_query,
  spot_booking_and_enroll_query,
} from "../../../../services/redux/slices/graphql/graphql_enroll_booking";
import DdAmountInput from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/dd_amount_input";
import SelectBank from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/select_bank";
import ReceiptDateInput from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/receipt_date_input";
import ReferenceNumber from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/reference_number";
import SpotBookAndEnrollModal from "./spot_book_and _enroll_modal";
import { IoMdClose } from "react-icons/io";
import BookingAmount from "../../booking/booking_form/booking_amount";
import SpotBookingAmount from "./spot_booking_leader_details/spot_booking_amount";
import EnrollConfirmationDetailsCard from "../../enroll/enroll_confirmation_details_card";
import CashReceived from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/cash_received";
import CashBalance from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/cash_balance";
import PaymentReference from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/payment_reference";

const SpotBookingForm = ({
  set_duplicate_booking_modal,
  set_receipt_modal_open,
  form,
  open_token_printer_modal,
  selected_id_proof,
  set_selected_id_proof,
  get_mobile_number,
  get_leader_mobile_no,
  set_leader_mobile_number,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  let modal_instance;

  const [initial_values, set_initial_values] = useState({});
  const [mandram_id, set_mandram_id] = useState();
  const [mandram_item, set_mandram_item] = useState({});
  const [selected_item, set_selected_item] = useState();
  const [time_slot_id, set_time_slot_id] = useState();
  const [leader_details, set_leader_details] = useState(false);
  const [new_user_details, set_new_user_details] = useState(true);
  const [get_leader_details, set_get_leader_details] = useState();
  const [value, set_value] = useState(1);
  const [spot_booking_id, set_spot_booking_id] = useState();
  const [date, set_date] = useState(null);
  const [date_format, set_date_format] = useState(null);
  const [district_id, set_district_id] = useState();
  const [existing_user, setExistingUser] = useState(false);
  const [dd_amount, set_dd_amount] = useState("");
  const [selected_bank, set_selected_bank] = useState("");
  const [selected_date, set_selected_date] = useState("");
  const [payment_mode, set_payment_mode] = useState("");
  const [spot_booking_amount, set_spot_booking_amount] = useState(0);
  const [spot_booking_confirmation, set_enroll_spot_booking_confirmation] =
    useState(false);
  const [spot_booking_enroll_values, set_spot_booking_enroll_values] =
    useState("");

  console.log("spot_booking_enroll_values", spot_booking_enroll_values);
  const [dd_total_amount, set_dd_total_amount] = useState();
  const currentTime = moment();
  const totalMinutes = currentTime.diff(moment().startOf("day"), "minutes");
  const hours = Math.floor(totalMinutes / 60) * 60;
  const items = useDynamicSelector("get_booking_user_details");
  const [leader_value, set_leader_value] = useState();
  const [cash_amount, set_cash_amount] = useState(0);
  const [balance_amount, set_balance_amount] = useState(0);
  const [is_duplicate_booking, set_is_duplicate_booking] = useState(false);

  const { items: districts } = useDynamicSelector("district_list");

  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");

  useEffect(() => {
    get_mobile_number(get_leader_mobile_no);
  }, [get_leader_mobile_no]);

  const {
    status: create_spot_booking_status,
    error: create_spot_booking_error,
    loading: create_spot_booking_loading,
    id: create_spot_booking_id,
  } = useDynamicSelector("create_spot_booking");

  const {
    payment_status: order_status,
    error: check_order_error,
    loading: check_order_loading,
    booking_id: check_order_booking_id,
  } = useDynamicSelector("check_order_status_for_spot_booking");

  const {
    id: create_spot_booking_enrolment_id,
    status: create_spot_booking_enrolment_status,
    error: create_spot_booking_enrolment_error,
    loading: spot_booking_enrolment_loading,
  } = useDynamicSelector("create_spot_booking_and_enroll");

  const { items: mandram_items } = useDynamicSelector("get_mandram_details");

  const handle_date_change = (date, dateString) => {
    set_selected_date(dateString);
  };

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_mandram_list = () => {
    let key = [{ key: "get_mandram_details", loading: true }];
    let query = query_get_mandram_list;
    let variables = {
      filter: { district_id: district_id },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_one_booking = () => {
    let key = [{ key: "get_booking_detail", loading: true }];
    let query = query_get_booking_details;
    let variables = {
      custom: {
        booking_reference: spot_booking_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const create_spot_booking_and_enroll = () => {
    let key = [{ key: "create_spot_booking_and_enroll", loading: true }];
    let query = spot_booking_and_enroll_query;
    let data = {
      payment_mode: payment_mode,
      dd_reference: spot_booking_enroll_values?.reference_number,
      dd_datetime: selected_date,
      dd_bank_id: selected_bank,
      dd_amount: dd_total_amount,
      count: spot_booking_enroll_values?.devotees_count,
      mandram_id: mandram_id,
      pos_receipt: spot_booking_enroll_values?.upi_reference,
      booking_user: {
        name: spot_booking_enroll_values?.spot_incharge_name,
        mobile: spot_booking_enroll_values?.contact_number,
        id_type: spot_booking_enroll_values?.id_type,
        id_number: spot_booking_enroll_values?.id_number,
      },
      booking_user_id: items?.id || undefined,
    };

    if (items?.id) {
      delete data.booking_user;
    } else {
      delete data?.booking_user_id;
    }

    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const create_spot_booking = (values) => {
    let key = [{ key: "create_spot_booking", loading: true }];
    let query = mutation_create_spot_booking;
    let data = {
      count: values.devotees_count,
      mandram_id,
      booking_user: {
        name: values.spot_incharge_name,
        mobile: values.contact_number,
        id_type: values.id_type,
        id_number: values.id_number,
      },
      booking_user_id: items?.id || undefined,
    };

    if (items?.id) {
      delete data.booking_user;
    } else {
      delete data.booking_user_id;
    }

    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const spot_book_enroll = () => {
    create_spot_booking_and_enroll();
  };

  const close_confirmation = () => {
    set_enroll_spot_booking_confirmation(false);
  };

  useEffect(() => {
    get_season_date_slot_list();
    get_district_list();
    dispatch(dynamicClear("get_booking_user_details"));
  }, []);

  useEffect(() => {
    if (district_id) {
      get_mandram_list();
    }
  }, [district_id]);

  useEffect(() => {
    if (selected_item) {
      let selected_time = season_date_slot_list_items?.find(
        (x) => x.id === selected_item?.id
      );

      set_time_slot_id(
        selected_time?.time_slots?.find(
          (d) => d.start_time_in_minutes === hours
        )?.id
      );
    }
  }, [selected_item]);

  useEffect(() => {
    form.setFieldsValue({ ...initial_values });
  }, [initial_values]);

  useEffect(() => {
    if (create_spot_booking_status === "booking created") {
      set_receipt_modal_open(true);
      set_is_duplicate_booking(false);
      set_spot_booking_id(create_spot_booking_id);
      dispatch(dynamicClear("create_spot_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
      form.resetFields();
      form.setFieldsValue({
        id_type: "Aadhaar Card",
      });
      set_cash_amount(0);
      set_spot_booking_amount(0);
      set_get_leader_details("");
      // set_leader_mobile_number("");
      set_leader_details(false);
      set_selected_id_proof("Aadhaar Card");
    } else if (create_spot_booking_error?.message) {
      if (
        create_spot_booking_error?.message ===
        "Cannot Read Properties Of Null Reading Date Slot"
      ) {
        set_is_duplicate_booking(true);
        create_spot_booking();
      } else if (
        create_spot_booking_error?.message ===
        "Already booking exist for this user"
      ) {
        set_spot_booking_id(create_spot_booking_id);
        if (is_duplicate_booking) {
          set_receipt_modal_open(true);
        } else {
          set_duplicate_booking_modal(true);
        }
        form.resetFields();
        form.setFieldsValue({
          id_type: "Aadhaar Card",
        });
        set_cash_amount(0);
        dispatch(dynamicClear("create_spot_booking"));
        dispatch(dynamicClear("get_booking_user_details"));
      } else {
        toast.error(create_spot_booking_error.message);
        dispatch(dynamicClear("create_spot_booking"));
      }
    }
  }, [create_spot_booking_status]);

  useEffect(() => {
    if (create_spot_booking_enrolment_status === "order created") {
      check_spot_booking_order_status(create_spot_booking_enrolment_id);
      modal_instance = Modal.info({
        title: "Payment Process",
        content: (
          <div>
            <p>Your Payment is Processing.....</p>
          </div>
        ),
        okButtonProps: {
          style: {
            display: "none",
          },
        },
      });
    } else if (create_spot_booking_enrolment_status === "booking enrolled") {
      open_token_printer_modal();
      set_enroll_spot_booking_confirmation(false);
      set_selected_item("");
      set_get_leader_details("");
      set_payment_mode("");
      set_spot_booking_amount(0);
      form.resetFields("");
      form.setFieldsValue({ cash_balance: 0 });
      // get_one_booking(check_order_booking_id);

      if (modal_instance) {
        modal_instance.destroy();
      }
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    } else if (
      create_spot_booking_enrolment_status === "booking created & paid"
    ) {
      open_token_printer_modal();
      set_selected_item("");
      set_get_leader_details("");
      set_payment_mode("");
      set_spot_booking_amount(0);

      // get_one_booking(check_order_booking_id);
      form.resetFields("");
      if (modal_instance) {
        modal_instance.destroy();
      }
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    } else if (create_spot_booking_enrolment_error?.message) {
      set_enroll_spot_booking_confirmation(false);
      // toast.error(create_spot_booking_enrolment_error.message);
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    }
  }, [create_spot_booking_enrolment_status, create_spot_booking_enrolment_id]);

  useEffect(() => {
    if (order_status === "completed") {
      open_token_printer_modal();
      set_receipt_modal_open(false);
      if (modal_instance) {
        modal_instance.destroy();
      }
      set_selected_item("");
      set_get_leader_details("");
      set_spot_booking_amount(0);
      form.setFieldsValue({ cash_balance: 0 });
      // get_one_booking(check_order_booking_id);
      form.resetFields("");
    } else if (order_status === "initiated") {
      setTimeout(() => {
        check_spot_booking_order_status(create_spot_booking_enrolment_id);
      }, 10000);
    } else if (order_status === "failure") {
      if (modal_instance) {
        modal_instance.destroy();
        toast.error("Payment failed");
      }
    } else if (check_order_error?.message) {
      toast.error(check_order_error?.message);
      dispatch(dynamicClear("check_order_status_for_spot_booking"));
    }
  }, [order_status, check_order_error, create_spot_booking_enrolment_id]);

  useEffect(() => {
    if (spot_booking_id) {
      get_one_booking();
    }
  }, [spot_booking_id]);

  useEffect(() => {
    form.setFieldsValue({
      id_type: "Aadhaar Card",
    });
  }, []);

  useEffect(() => {
    if (payment_mode === "dd") {
      const total_amount = spot_booking_amount * 350;
      set_dd_total_amount(total_amount);
      form.setFieldsValue({ dd_amount: total_amount });
    }
  }, [payment_mode, spot_booking_amount, dd_total_amount]);

  useEffect(() => {
    if (payment_mode === "cash") {
      const bal_amount = cash_amount - spot_booking_amount;
      if (bal_amount < 0) {
        set_balance_amount(0);
        form.setFieldsValue({ cash_balance: 0 });
      } else {
        set_balance_amount(bal_amount);
        form.setFieldsValue({ cash_balance: bal_amount || 0 });
      }
    }
  }, [payment_mode, spot_booking_amount, cash_amount]);

  const check_spot_booking_order_status = (create_booking_id) => {
    let key = [{ key: "check_order_status_for_spot_booking", loading: true }];
    let query = check_order_status_for_spot_booking_query;
    let variables = {
      id: create_booking_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_finish = (values) => {
    set_spot_booking_enroll_values(values);
    if (role === "Enrolment Officer") {
      set_enroll_spot_booking_confirmation(true);
    } else {
      create_spot_booking(values);
      set_value(1);
    }
  };

  return (
    <div>
      <Form
        name="basic"
        style={{
          width: "90%",
        }}
        layout="vertical"
        onFinish={on_finish}
        form={form}
        id="spot_booking_form"
      >
        <Row gutter={24}>
          <Col span={12}>
            <SpotBookingDistrict
              districts={districts}
              set_district_id={set_district_id}
              get_mandram_list={get_mandram_list}
            />
          </Col>
          <Col span={12}>
            <SpotBookingMandram
              set_initial_values={set_initial_values}
              mandram_items={mandram_items}
              set_mandram_id={set_mandram_id}
              set_mandram_item={set_mandram_item}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <SpotBookingDevoteesCount
              set_spot_booking_amount={set_spot_booking_amount}
            />
          </Col>
          <Col span={12}>
            <SpotBookingAmount spot_booking_amount={spot_booking_amount} />
          </Col>
        </Row>
        <SpotBookingLeaderDetails
          set_leader_details={set_leader_details}
          leader_details={leader_details}
          set_new_user_details={set_new_user_details}
          get_leader_details={get_leader_details}
          set_get_leader_details={set_get_leader_details}
          setExistingUser={setExistingUser}
          set_value={set_value}
          value={value}
          new_user_details={new_user_details}
          get_leader_mobile_no={get_leader_mobile_no}
          set_leader_mobile_number={set_leader_mobile_number}
          form={form}
          selected_id_proof={selected_id_proof}
          set_selected_id_proof={set_selected_id_proof}
          leader_value={leader_value}
          set_leader_value={set_leader_value}
        />

        <div style={{ marginTop: "20px" }}>
          {role === "Enrolment Officer" && (
            <PaymentModeInput
              set_payment_mode={set_payment_mode}
              payment_mode={payment_mode}
            />
          )}
        </div>
        {payment_mode === "dd" ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "25%" }}>
              <DdAmountInput set_dd_amount={set_dd_amount} />
            </div>
            <div style={{ width: "20%" }}>
              <SelectBank
                set_selected_bank={set_selected_bank}
                payment_mode={payment_mode}
              />
            </div>
            <div style={{ width: "20%" }}>
              <ReceiptDateInput
                set_selected_date={set_selected_date}
                selected_date={selected_date}
                handle_date_change={handle_date_change}
                payment_mode={payment_mode}
              />
            </div>
            <div style={{ width: "20%" }}>
              <ReferenceNumber payment_mode={payment_mode} />
            </div>
          </div>
        ) : (
          ""
        )}
        {payment_mode === "cash" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <CashReceived set_cash_amount={set_cash_amount} />
            <CashBalance />
          </div>
        ) : (
          ""
        )}
        {payment_mode === "card" || payment_mode === "upi" ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <PaymentReference payment_mode={payment_mode} />
          </div>
        ) : (
          ""
        )}
        {role === "Enrolment Officer" ||
        role === "Spl Enrolment Officer" ||
        role === "VIP Enrolment Officer" ||
        role === "Bank Enrolment Officer" ? (
          ""
        ) : (
          <SpotBookingSubmit title={t("book")} />
        )}
      </Form>
      <Modal
        title={"Confirmation"}
        open={spot_booking_confirmation}
        onCancel={() => set_enroll_spot_booking_confirmation(false)}
        footer={[
          <Button
            style={{
              color: "white",
              border: "transparent",
              backgroundColor: "#f96451",
            }}
            onClick={close_confirmation}
          >
            No
          </Button>,
          <Button
            style={{
              color: "white",
              border: "transparent",
              backgroundColor: "#168f92",
            }}
            onClick={spot_book_enroll}
            loading={spot_booking_enrolment_loading}
          >
            Yes
          </Button>,
        ]}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
      >
        <Typography style={{ marginBottom: "15px" }}>
          Do you want to enroll for the following details ?
        </Typography>
        <EnrollConfirmationDetailsCard
          title={"Mandram"}
          value={mandram_item?.name}
        />
        <EnrollConfirmationDetailsCard
          title={"Code"}
          value={mandram_item?.code}
        />
        <EnrollConfirmationDetailsCard
          title={"Devotees"}
          value={spot_booking_enroll_values?.devotees_count}
        />
      </Modal>
    </div>
  );
};

export default SpotBookingForm;
