import { Box, Text, VStack } from "native-base";
import React from "react";
import IrumudiPortalForm from "./irumudi_portal_form/irumudi_portal_main_form";
import { Col, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

const IrumudiPortal = () => {
  const { t } = useTranslation();
  return (
    <>
      <Col>
        <Row
          style={{
            backgroundColor: "white",
            height: "15%",
            width: "100%",
            marginLeft: "50px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            fontSize: "15px",
            alignItems: "center",
            backgroundColor: "#FFDE8B",
          }}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                width: "100%",
                color: "black",
                marginTop: "15px",
                fontWeight: "bold",
                fontSize: "15px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("irumudi_receipt_title")}
            </Typography>
          </div>
        </Row>
        <Row
          style={{
            // height: "100%",
            width: "100%",
            backgroundColor: "white",
            padding: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50px",
            borderRadius: "0 0 10px 10px",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            backgroundColor: "#FFDE8B",
          }}
        >
          <IrumudiPortalForm />
        </Row>
      </Col>
    </>
  );
};

export default IrumudiPortal;
