import React, { useEffect, useState } from "react";
import { Collapse, Form, Spin, Table } from "antd";
import BookingHeaderList from "./booking_history_header_list";
import BookingMandramList from "./booking_history_mandram_header_list";
import BookingHistoryTable from "./booking_history_table";
import BookingHistoryCollapseButton from "./booking_history_collapse_button";
import { useDynamicSelector } from "../../../services/redux/selector";
import DistrictHeaderList from "./booking_history_form/booking_history_district_header_list";
import { district_details_query } from "../../../services/redux/slices/graphql/graphql_district";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_get_all_mandrams,
  query_get_mandram_list,
  query_get_mandram_wise_summary,
} from "../../../services/redux/slices";
import BookingHistoryList from "./booking_history_list";
import Loading from "../system/ui/loading/loading";
import moment from "moment-timezone";
import CustomNoData from "../common/custom_no_data";
import LoaderSpinner from "../common/custom_spinner";

const BookingHistoryCollapse = ({
  form,
  table_open,
  get_date_district_mandram_wise_summary,
  mandram_item,
  set_calculated_grand_total_values,
}) => {
  const dispatch = useDispatch();
  const [active_key, set_active_key] = useState([]);
  const [active_district_keys, set_active_district_keys] = useState([]);
  const [active_mandram_keys, set_active_mandram_keys] = useState([]);
  const [mandram_details, set_mandram_details] = useState({});
  const [booking_summary_list, set_booking_summary_list] = useState([]);
  const [booking_mandram_summary_list, set_mandram_booking_summary_list] =
    useState([]);
  const [mandram_summary_key_value, set_mandram_summary_key_value] =
    useState("");

  const date_slot = Form.useWatch("date_slot_id_list", form);

  const { items: booking_summary, loading: booking_summary_loading } =
    useDynamicSelector("get_date_district_mandram_wise_summary");

  const { items: districts } = useDynamicSelector("district_list");

  const { items: mandram_summary, loading: mandram_summary_loading } =
    useDynamicSelector("get_mandram_wise_summary");

  const get_all_mandram_list = () => {
    let key = [{ key: "get_all_mandrams", loading: true }];
    let query = query_get_all_mandrams;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  const mandram_wise_summary = (filter) => {
    let key = [{ key: "get_mandram_wise_summary", loading: true }];
    let query = query_get_mandram_wise_summary;
    let variables = {
      filter,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_mandram_list();
  }, []);

  useEffect(() => {
    if (booking_summary?.length > 0) {
      let temp = booking_summary?.filter((x) => x.district_id !== null);
      set_booking_summary_list(temp);
    }
  }, [booking_summary]);

  useEffect(() => {
    if (mandram_summary?.length > 0) {
      set_mandram_booking_summary_list(mandram_summary);
    }
  }, [mandram_summary]);

  const grouped_by_date = booking_summary_list?.reduce((acc, item) => {
    const date = moment(item.date).tz("Asia/Kolkata").format("DD/MM/YYYY");
    if (!acc[date]) {
      acc[date] = {
        date,
        date_slot_id: item.date_slot_id,
        total_arrived: 0,
        total_booked: 0,
        total_enrolled: 0,
        total_yet_to_enroll_count: 0,
        total_cancelled_count: 0,
        total_excess_count: 0,
        total_received_amount: 0,
        total_prepaid_amount: 0,
        enrolled: 0,
        districts: {},
      };
    }
    acc[date].total_arrived += item?.count?.arrived;
    acc[date].total_booked += item?.count?.booked;
    acc[date].total_yet_to_enroll_count += item?.count?.yet_to_enroll;
    acc[date].total_cancelled_count += item?.count?.cancelled;
    acc[date].total_excess_count += item?.count?.excess;
    acc[date].total_enrolled += item?.count?.enrolled;
    acc[date].total_received_amount += item.received_amount;
    acc[date].total_prepaid_amount += item.prepaid_amount;

    if (item.district_id && districts) {
      if (!acc[date].districts[item.district_id]) {
        acc[date].districts[item.district_id] = {
          date,
          district_id: item.district_id,
          district_name: districts?.find(
            (list) => list?.id === item?.district_id
          )?.name,
          total_arrived: 0,
          total_booked: 0,
          total_enrolled: 0,
          total_yet_to_enroll_count: 0,
          total_cancelled_count: 0,
          total_excess_count: 0,
          total_received_amount: 0,
          total_prepaid_amount: 0,
          mandram_items: {},
        };
      }
      acc[date].districts[item.district_id].total_arrived +=
        item?.count?.arrived;
      acc[date].districts[item.district_id].total_booked += item?.count?.booked;
      acc[date].districts[item.district_id].total_yet_to_enroll_count +=
        item?.count?.yet_to_enroll;
      acc[date].districts[item.district_id].total_cancelled_count +=
        item?.count?.cancelled;
      acc[date].districts[item.district_id].total_excess_count +=
        item?.count?.excess;
      acc[date].districts[item.district_id].total_enrolled +=
        item?.count?.enrolled;
      acc[date].districts[item.district_id].total_received_amount +=
        item?.received_amount;
      acc[date].districts[item.district_id].total_prepaid_amount +=
        item?.prepaid_amount;
    }

    return acc;
  }, {});

  useEffect(() => {
    calculate();
  }, [booking_summary_list]);

  const calculate = () => {
    let sum_total_booked = 0;
    let sum_total_enrolled = 0;
    let sum_total_cancelled = 0;
    let sum_total_yet_to_enroll = 0;
    let sum_total_excess = 0;
    let sum_total_prepaid_amount = 0;

    // Iterate through each date in grouped_by_date
    for (const date in grouped_by_date) {
      // Accumulate counts for each date
      sum_total_booked += grouped_by_date[date].total_booked || 0;
      sum_total_enrolled += grouped_by_date[date].total_enrolled || 0;
      sum_total_cancelled += grouped_by_date[date].total_cancelled_count || 0;
      sum_total_yet_to_enroll +=
        grouped_by_date[date].total_yet_to_enroll_count || 0;
      sum_total_excess += grouped_by_date[date].total_excess_count || 0;
      sum_total_prepaid_amount +=
        grouped_by_date[date].total_prepaid_amount || 0;
    }

    set_calculated_grand_total_values({
      sum_total_booked,
      sum_total_enrolled,
      sum_total_cancelled,
      sum_total_yet_to_enroll,
      sum_total_excess,
      sum_total_prepaid_amount,
    });
  };

  const handle_date_collapse = (key, item, data) => {
    dispatch(dynamicClear("get_mandram_wise_summary"));
    dispatch(dynamicClear("get_booking_list"));
    // set_active_district_keys([]);
    set_active_mandram_keys([]);

    if (key === data[key]?.date) {
      set_active_key([key]);
    }
  };

  const handle_district_collapse = (key, item, list, active_key) => {
    let split_key = key?.split("_")?.[1];
    set_mandram_summary_key_value(split_key);
    dispatch(dynamicClear("get_mandram_wise_summary"));
    dispatch(dynamicClear("get_booking_list"));
    const district_id = list?.districts[split_key]?.district_id;
    if (split_key === list?.districts[split_key]?.district_id) {
      let filter = {
        district_id: district_id,
        date_slot_id: list?.date_slot_id,
        mandram_id_list: mandram_item,
      };
      set_active_district_keys([key]);
      mandram_wise_summary(filter);
    }

    set_active_mandram_keys([]);
  };

  const handle_change_mandram_collapse = (key, item, list) => {
    set_mandram_details(item);

    set_active_mandram_keys([key]);
  };

  // useEffect(() => {
  //   get_date_district_mandram_wise_summary();
  // }, []);
  console.log("Object", Object.keys(grouped_by_date));
  return (
    <div>
      {table_open && (
        <div>
          {/* <BookingHistoryCollapseButton
            setExpand={set_is_expand}
            collapse_all_panels={collapse_all_panels}
            booking_summary={grouped_by_date}
            expand_all_panels={expand_all_panels}
          /> */}
          {booking_summary_loading ? (
            <LoaderSpinner message={"Please wait..."} />
          ) : (
            <div style={{ marginTop: "20px" }}>
              <Collapse
                accordion={true}
                activeKey={active_key}
                onChange={(key, al, all) => {
                  set_active_key(key ? [key] : []);
                  // set_active_district_keys([]);
                  handle_date_collapse(
                    key,
                    grouped_by_date[key[key?.length - 1]],
                    grouped_by_date
                  );
                }}
              >
                {Object.keys(grouped_by_date)?.map((item, index) => (
                  <Collapse.Panel
                    key={item}
                    style={{ backgroundColor: "#275670", color: "white" }}
                    header={<BookingHeaderList item={grouped_by_date[item]} />}
                  >
                    <Collapse
                      style={{ backgroundColor: "#23545B", color: "white" }}
                      accordion={true}
                      activeKey={active_district_keys}
                      onChange={(key, val, values) => {
                        handle_district_collapse(
                          key,
                          grouped_by_date[item]?.districts[
                            key[key?.length - 1]
                          ],
                          grouped_by_date[item],
                          ""
                        );
                      }}
                    >
                      {Object.keys(grouped_by_date[item]?.districts)?.map(
                        (district_id) => (
                          <Collapse.Panel
                            key={`${item}_${district_id}`}
                            header={
                              <DistrictHeaderList
                                district={
                                  grouped_by_date[item]?.districts[district_id]
                                }
                              />
                            }
                          >
                            {mandram_summary_loading ? (
                              <Loading marginTop="10px" />
                            ) : (
                              <Collapse
                                accordion={true}
                                activeKey={active_mandram_keys}
                                onChange={(key, value) => {
                                  handle_change_mandram_collapse(
                                    key,
                                    booking_mandram_summary_list[Number(key)],
                                    booking_mandram_summary_list
                                  );
                                }}
                              >
                                {mandram_summary_key_value === district_id &&
                                  booking_mandram_summary_list?.map(
                                    (mandram_name, index) => (
                                      <Collapse.Panel
                                        key={index}
                                        style={{
                                          backgroundColor: "#34495E",
                                          color: "white",
                                        }}
                                        header={
                                          <BookingMandramList
                                            item={mandram_name}
                                          />
                                        }
                                      >
                                        <div key={index}>
                                          <BookingHistoryList
                                            mandram_name={
                                              active_mandram_keys?.length !== 0
                                                ? mandram_details
                                                : {}
                                            }
                                            active_mandram_keys={
                                              active_mandram_keys
                                            }
                                          />
                                        </div>
                                      </Collapse.Panel>
                                    )
                                  )}
                              </Collapse>
                            )}
                          </Collapse.Panel>
                        )
                      )}
                    </Collapse>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          )}
        </div>
      )}

      {Object.keys(grouped_by_date)?.length < 1 && !booking_summary_loading && (
        <div style={{ marginTop: "10px" }}>
          <CustomNoData description={"No booking history"} />
        </div>
      )}
    </div>
  );
};

export default BookingHistoryCollapse;
