import {
  Button,
  Col,
  Collapse,
  Input,
  Pagination,
  Skeleton,
  Space,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import ExpandableMandramForm from "./expandable_mandram_form";
import DeleteMandramModal from "./delete_mandram_modal";
import AddMandramModal from "./add_mandram/add_mandram_modal";
import { useDynamicSelector } from "../../../services/redux/selector";
import {
  dynamicRequest,
  get_mandram_list_query,
  query_get_mandram,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { retrieveItem } from "../../../helpers/functions";

const { Panel } = Collapse;

const MandramList = (props) => {
  const { get_all_mandram_list, get_one_mandram, selected_district_id } = props;
  const role = retrieveItem("role");

  const [expanded_row_key, set_expanded_row_key] = useState([]);
  const [delete_mandram, set_delete_mandram] = useState("");
  const [header_type, set_header_type] = useState("");
  const [add_mandram, set_add_mandram] = useState("");
  const [mandram_delete_id, set_mandram_delete_id] = useState("");
  const [mandram_delete_name, set_delete_mandram_name] = useState("");
  const [search_text, set_search_text] = useState({});
  const [searched_column, set_searched_column] = useState(null);
  const search_input = useRef(null);

  const {
    items: mandram_items,
    pagination,
    loading: mandram_loading,
  } = useDynamicSelector("get_mandram_list");
  const { id: get_one_mandram_id } = useDynamicSelector("get_mandram");

  const table_row_class_name = (record, index) => {
    return index % 2 === 0 ? "odd-row" : "even-row";
  };

  const handle_delete_mandram = (record) => {
    set_header_type("Delete Mandram");
    set_delete_mandram(true);
    set_mandram_delete_id(record.id);
    set_delete_mandram_name(record.name);
  };
  const handle_edit_mandram = (record) => {
    set_header_type("Edit Mandram");
    get_one_mandram(record.id);
    set_add_mandram(true);
  };

  const handle_search = (selectedKeys, confirm, data_index) => {
    confirm();
    set_search_text({ ...search_text, [data_index]: selectedKeys[0] });
  };

  const handle_reset = (clearFilters, data_index) => {
    clearFilters();
    set_search_text({ ...search_text, [data_index]: "" });
  };

  const handle_row_click = (expanded, record) => {
    if (expanded) {
      get_one_mandram(record.id);
      set_expanded_row_key([record.id]);
    } else {
      set_expanded_row_key([]);
    }
  };

  const get_column_search_props = (data_index, custom_title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={search_input}
          placeholder={`Search ${custom_title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handle_search(selectedKeys, confirm, data_index)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handle_reset(clearFilters, data_index)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handle_search(selectedKeys, confirm, data_index)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : "white" }} />
    ),
    onFilter: (value, record) =>
      record[data_index]
        ? record[data_index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => search_input.current?.select(), 100);
      }
    },
    render: (text) =>
      searched_column === data_index ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[search_text[data_index]]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      align: "center",
      width: "200px",
      height: "100px",
      ...get_column_search_props("code", "Code"),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "250px",
      height: "100px",
      ...get_column_search_props("name", "Name"),
    },
    {
      title: "District",
      dataIndex: ["district", "name"],
      align: "left",
      width: "300px",
      height: "100px",
      ...get_column_search_props(["district", "name"], "District"),
    },
    {
      title: "Incharge",
      dataIndex: "incharge_name",
      align: "left",
      width: "200px",
      height: "100px",
      ...get_column_search_props("incharge", "Incharge"),
    },
    {
      title: "Incharge Mobile",
      dataIndex: "incharge_mobile",
      align: "center",
      width: "300px",
      height: "100px",
      ...get_column_search_props("incharge_mobile", "Incharge Mobile"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   align: "left",
    //   width: "100px",
    //   height: "100px",
    // },
    // {
    //   title: "Sakthi Peedam",
    //   align: "left",
    //   width: "300px",
    //   height: "100px",
    //   render: (is_sakthi_peedam) => (is_sakthi_peedam ? "Yes" : "No"),
    // },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: "300px",
      height: "100px",
      render: (_, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined
            onClick={() => {
              handle_edit_mandram(record);
            }}
          />
          {role === "Super Admin" ? (
            <DeleteOutlined onClick={() => handle_delete_mandram(record)} />
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <Col>
      <Table
        style={{
          width: "100%",
          marginTop: "10px",
          cursor: "pointer",
          overflowY: "scroll",
        }}
        columns={columns}
        dataSource={mandram_items}
        pagination={false}
        size="large"
        key={"id"}
        loading={mandram_loading}
        rowClassName={(record, index) => table_row_class_name(record, index)}
        // expandable={{
        //   expandedRowRender: (record, index, values) => (
        //     <ExpandableMandramForm
        //       mandram_items={[record?.admin]}
        //       key={index}
        //       values={values}
        //       record_id={record.id}
        //       expanded_row_key={expanded_row_key[record.id]}
        //     />
        //   ),
        //   onExpand: (expanded, record) => handle_row_click(expanded, record),
        //   rowExpandable: (record) => true,
        //   expandedRowKeys: expanded_row_key ? [expanded_row_key] : [],
        // }}
      />

      <DeleteMandramModal
        delete_mandram={delete_mandram}
        set_delete_mandram={set_delete_mandram}
        set_header_type={set_header_type}
        header_type={header_type}
        mandram_delete_id={mandram_delete_id}
        get_all_mandram_list={get_all_mandram_list}
        mandram_delete_name={mandram_delete_name}
      />
      <AddMandramModal
        header_type={header_type}
        set_header_type={set_header_type}
        add_mandram={add_mandram}
        set_add_mandram={set_add_mandram}
        get_all_mandram_list={get_all_mandram_list}
      />
    </Col>
  );
};

export default MandramList;
