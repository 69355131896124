import React, { useEffect, useState } from "react";
import { Input, Button, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

export const CustomSearchBox = ({ on_search, placeholder }) => {
  const [search_text, set_search_text] = useState("");

  const handle_search = () => {
    on_search(search_text);
  };

  useEffect(() => {
    if (search_text.length < 1) {
      handle_search();
    }
  }, [search_text]);

  return (
    <Row className="search-box">
      <Input
        style={{
          height: "35px",
          width: "220px",
          border: "1px solid lightgrey",
        }}
        placeholder={placeholder ? placeholder : "Search..."}
        value={search_text}
        onChange={(e) => set_search_text(e.target.value)}
        onPressEnter={handle_search}
        suffix={
          <Button
            icon={<SearchOutlined />}
            onClick={handle_search}
            style={{
              backgroundColor: blue[4],
              color: "white",
              height: "32px",
              width: "32px",
              marginRight: "-10px",
              border: "transparent",
            }}
          />
        }
      />
    </Row>
  );
};
