import { Button, Card, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  get_booking_history_query,
} from "../../../../../services/redux";
import BookingReportFromDate from "../inputs/booking_report_from_date";
import BookingReportToDate from "../inputs/booking_report_to_date";
import BookingReportDistrict from "../inputs/booking_report_distrtict";
import BookingReportMandram from "../inputs/booking_report_mandram";
import { retrieveItem } from "../../../../../helpers/functions";

const BookingReportSearch = (props) => {
  const { set_from_date, set_to_date } = props;
  const [form] = Form.useForm();
  const role = retrieveItem("role");
  const dispatch = useDispatch();
  const [date, set_date] = useState({});
  const [district_id, set_district_id] = useState();

  const handle_finish = (values) => {
    get_booking_report_api(values);
  };

  const get_booking_report_api = (values) => {
    let keys = [{ key: "get_booking_report", loading: true }];
    let query = get_booking_history_query;
    let variable = {
      filter: {
        from_date: values?.from_date,
        to_date: values?.to_date,
        district_id: values?.district_name_input,
        mandram_id: values?.mandram_name_input,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_clear = () => {
    form.resetFields("");
  };
  const on_change_values = () => {
    dispatch(dynamicClear("get_booking_report"));
  };

  return (
    <div>
      <Form
        id="search"
        form={form}
        onFinish={handle_finish}
        onValuesChange={on_change_values}
      >
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            margin: "auto",
            backgroundColor: "#e7e7e7",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div style={{ marginBottom: "-15px" }}>
            <Row gutter={24}>
              <Col span={10}>
                <BookingReportFromDate set_from_date={set_from_date} />
              </Col>
              <Col span={10}>
                <BookingReportToDate set_to_date={set_to_date} />
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: "#014477",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    {"Get"}
                  </Button>
                </Form.Item>
              </Col>
              {(role === "Super Admin" || role === "MASM Admin") && (
                <>
                  <Col span={10}>
                    <BookingReportDistrict set_district_id={set_district_id} />
                  </Col>
                </>
              )}
              {(role === "Super Admin" ||
                role === "MASM Admin" ||
                role === "District Admin" ||
                role === "District User") && (
                <Col span={10}>
                  <BookingReportMandram district_id={district_id} />
                </Col>
              )}

              {/* <Col span={4}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: "#014477",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    {"Get"}
                  </Button>
                </Form.Item>
              </Col> */}
            </Row>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default BookingReportSearch;
