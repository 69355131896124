import React, { useEffect, useRef, useState } from "react";
import AddressReportMainComponent from "../components/reports_page/address_report/address_report_main_page";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import AddressReportListItems from "../components/reports_page/address_report/address_report_list/address_report_list_items";
import {
  dynamicRequest,
  query_address_report,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import LoaderSpinner from "../components/common/custom_spinner";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import { capitalize } from "lodash";
import { export_to_xlsx } from "../../helpers/functions";

const AddressWiseReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected_mandram, set_selected_mandram] = useState();
  const [district_name, set_district_name] = useState("");
  console.log("district_name", district_name);
  const [selected_date, set_selected_date] = useState();
  console.log("selected_date", selected_date);

  const componentRef = useRef();

  const { items: address_list_items, loading: address_list_loading } =
    useDynamicSelector("get_address_report_summary");

  useEffect(() => {
    address_report();
  }, []);

  const address_report = (values) => {
    let key = [{ key: "get_address_report_summary", loading: true }];
    let query = query_address_report;
    let variables = {
      filter: {
        district_id: values?.district,
        mandram_id_list: values?.mandram,
        from_date: values?.from_date,
        to_date: values?.to_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    handlePrint();
  };

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Leader Name"]: data_items[i]?.leader_name ?? "-",
        ["ID"]: data_items[i]?.id_number ?? "-",
        ["Address"]: data_items[i]?.address ?? "-",
        ["District Name"]: capitalize(data_items[i]?.district_name) ?? "-",
        ["District Code"]: data_items[i]?.district_code ?? "-",
        ["Mandram"]: data_items[i]?.mandram_name ?? "-",
        ["Mandram Code"]: data_items[i]?.mandram_code ?? "-",
        ["Mobile"]: data_items[i]?.mobile ?? "-",
      };

      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(address_list_items || []);
    export_to_xlsx(arranged_data, "Address_wise_report");
  };

  return (
    <div>
      <CustomPageTitle title={t("address_title")} />

      <AddressReportMainComponent
        address_report={address_report}
        set_selected_mandram={set_selected_mandram}
        set_district_name={set_district_name}
        set_selected_date={set_selected_date}
      />

      {address_list_items?.length > 0 ? (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
            width: "97%",
          }}
        >
          <Button
            disabled={address_list_items?.length > 0 ? false : true}
            style={{
              marginRight: "10px",
              backgroundColor: "#014477",
              color: "white",
            }}
            onClick={on_print}
          >
            {t("print")}
          </Button>
          <Button
            disabled={address_list_items?.length > 0 ? false : true}
            style={{ backgroundColor: "#185c37", color: "white" }}
            onClick={on_xlsx_download}
          >
            {t("download")}
          </Button>
        </div>
      ) : null}

      {address_list_items?.length > 0 ? (
        <div ref={componentRef}>
          <AddressReportListItems
            district_name={district_name}
            selected_date={selected_date}
          />
        </div>
      ) : null}

      {address_list_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}
    </div>
  );
};

export default AddressWiseReport;
