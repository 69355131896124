import React, { useEffect, useState } from "react";
import { Pagination, Space, Table } from "antd";
import CustomDeleteModal from "../../common/custom_delete_modal";
import DistrictUserModal from "../district_modal/district_user_modal";
import { useDispatch } from "react-redux";
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ManagingProvisionModal from "../../managing_provisions/managing_provision_modal";

import { toast } from "react-toastify";
import {
  mutation_delete_district_user,
  query_get_district_users_list,
} from "../../../../services/redux/slices/graphql/graphql_district";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  query_get_accessible_mandram_list,
  query_get_all_mandram_list,
  useDynamicSelector,
} from "../../../../services/redux";

const SubUserTable = (props) => {
  const dispatch = useDispatch();
  const {
    header_type,
    set_header_type,
    add_user_modal_visible,
    set_add_user_modal_visible,
    expandable_district_index,
    get_one_district,
    get_all_districts_users,
    record_id,
    color,
    district_id,
    mandrams_loading,
  } = props;
  const { t } = useTranslation();
  const [district_users_list, set_district_users_list] = useState([]);
  const [district_user_id, set_district_user_id] = useState({});

  useEffect(() => {
    get_all_districts_users(record_id);
  }, [record_id]);

  const {
    items: district_users,
    pagination,
    loading: district_users_loading,
  } = useDynamicSelector("get_district_user_list");

  useEffect(() => {
    set_district_users_list(district_users);
  }, [district_users]);

  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [current_page_limit, set_current_page_limit] = useState(5);
  const [current_page, set_current_page] = useState(1);
  const [delete_record, set_delete_record] = useState({});
  const [edit_record, set_edit_record] = useState({});
  const [mandram_list, set_mandram_list] = useState([]);
  const [district_user_name, set_district_user_name] = useState();

  const [
    managing_provision_modal_visible,
    set_managing_provision_modal_visible,
  ] = useState(false);

  const { status: accessible_mandram_status, error: accessible_mandram_error } =
    useDynamicSelector("set_accessible_mandram_list");

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_district_user_list", loading: true }];
    let query = query_get_district_users_list;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
      filter: {
        district_id: record_id ? record_id : "",
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  const handle_edit_user = (record) => {
    set_header_type("edit_user");
    set_edit_record(record);
    set_add_user_modal_visible(true);
  };

  const handle_edit_user_modal_close = () => {
    set_add_user_modal_visible(false);
    set_header_type("");
  };
  const handle_delete_user = (record) => {
    set_delete_record(record);
    set_delete_modal_visible(true);
  };

  const get_all_mandram_list = (val) => {
    let key = [{ key: "get_mandrams", loading: true }];
    let query = query_get_all_mandram_list;
    let variable = {
      filter: {
        district_id: record_id,
        search_string: val || "",
      },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handle_modal_open = (record) => {
    set_district_user_name(record?.name);
    set_managing_provision_modal_visible(true);
    get_all_mandram_list();
    set_district_user_id(record?.system_user?.id);
    get_all_accessible_mandram_list(record?.system_user?.id);
  };

  const get_all_accessible_mandram_list = (id) => {
    let key = [{ key: "get_accessible_mandram_list", loading: true }];
    let query = query_get_accessible_mandram_list;
    let variables = {
      id: id,
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (accessible_mandram_status === "success") {
      toast.success(`Mandram(s) assigned to ${district_user_name}`);
      dispatch(dynamicClear("set_accessible_mandram_list"));
    } else if (accessible_mandram_error?.message) {
      toast.error(accessible_mandram_error.message);
      dispatch(dynamicClear("set_accessible_mandram_list"));
    }
  }, [accessible_mandram_status, accessible_mandram_error]);

  const delete_district_user = () => {
    let keys = [{ key: "delete_district_user", loading: true }];
    let query = mutation_delete_district_user;
    let variable = {
      id: delete_record?.system_user.id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "400px",
    },
    {
      title: "Username",
      width: "400px",
      render: (item) => {
        return item?.system_user?.username;
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      width: "400px",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "400px",
      align: "left",
      render: (text, record) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "400px",
      render: (_, record) => (
        <>
          <Space
            style={{
              width: "75px",
              justifyContent: "space-between",
            }}
          >
            <AppstoreAddOutlined
              onClick={() => handle_modal_open(record)}
              // loading={get_accessible_mandram_loading}
            />
            <EditOutlined onClick={() => handle_edit_user(record)} />
            <DeleteOutlined onClick={() => handle_delete_user(record)} />
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        key={expandable_district_index}
        scroll={{ x: 200 }}
        style={{
          width: "100%",
          borderRadius: "20px",
          backgroundColor: color,
        }}
        columns={columns}
        dataSource={district_users_list}
        size="large"
        loading={district_users_loading}
        pagination={{
          current: current_page,
          pageSize: current_page_limit,
          total: pagination?.total_count,
          showSizeChanger: true,
          onChange: fetch_data,
          onShowSizeChange: (current, size) => {
            get_all_districts_users(current, size);
          },
        }}
      />

      <ManagingProvisionModal
        managing_provision_modal_visible={managing_provision_modal_visible}
        set_managing_provision_modal_visible={
          set_managing_provision_modal_visible
        }
        get_all_mandram_list={get_all_mandram_list}
        district_user_id={district_user_id}
        mandram_list={mandram_list}
        set_mandram_list={set_mandram_list}
      />

      <CustomDeleteModal
        selector_name="delete_district_user"
        text={t("district_user")}
        delete_modal_visible={delete_modal_visible}
        set_delete_modal_visible={set_delete_modal_visible}
        get_all={get_all_districts_users}
        delete_api={delete_district_user}
        record={delete_record}
      />

      <DistrictUserModal
        district_id={district_id}
        edit_record={edit_record}
        record_id={record_id}
        get_all={get_all_districts_users}
        get_one_district={get_one_district}
        handle_edit_user_modal_close={handle_edit_user_modal_close}
        add_user_model_visible={add_user_modal_visible}
        set_add_user_modal_visible={set_add_user_modal_visible}
        header_type={header_type}
      />
    </>
  );
};

export default SubUserTable;
