import { Form, Select, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  IoCheckmarkDoneCircleSharp,
  IoCheckmarkDoneCircleOutline,
} from "react-icons/io5";
// import { Pressable } from "native-base";
const BookingHistoryAbishekam = ({
  search_abishekam_date,
  selected_options,
  set_selected_options,
  selectAll,
  setSelectAll,
  set_selected_date,
  set_selected_last_least_date,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: season_date_slot_list_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const options = season_date_slot_list_items || [];

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  const handleChange = (value, option) => {
    set_selected_options(value);
    const selected_dates = option?.map((option) => option?.children) || [];
    console.log("option", option);
    set_selected_date(selected_dates);

    // Check if there are dates selected before computing least and last dates
    const dates_selected = selected_dates.length > 0;

    if (dates_selected) {
      const moment_dates = selected_dates.map((dateString) =>
        moment(dateString, "DD-MMM-YYYY")
      );
      const leastDate = moment.min(moment_dates);
      const lastDate = moment.max(moment_dates);

      const leastDateString = leastDate.format("DD-MMM-YYYY");
      const lastDateString = lastDate.format("DD-MMM-YYYY");

      set_selected_last_least_date({
        least_date: leastDateString,
        last_date: lastDateString,
      });
    } else {
      // Clear selected_last_least_date when no dates are selected
      set_selected_last_least_date({});
    }
  };

  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  return (
    <Form.Item
      label={t("abishekam_date")}
      name="date_slot_id_list"
      style={{ width: "300px" }}
    >
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: "10px",
        }}
      > */}
      <Select
        className="abishekam-date"
        showSearch
        filterOption={custom_filter_option}
        mode="multiple"
        style={{ width: "100%", color: "black" }}
        // value={selected_options}
        onChange={handleChange}
        placeholder={<Typography> All Dates</Typography>}
        allowClear={true}
      >
        {options?.map((option) => (
          <Option key={option?.id} value={option.id}>
            {option.date_string}
          </Option>
        ))}
      </Select>
      {/* <Pressable onPress={selected_abishekam_date}>
          {selectAll ? (
            <Tooltip title="Deselect all">
              <IoCheckmarkDoneCircleSharp size={25} />
            </Tooltip>
          ) : (
            <Tooltip title="Select all">
              <IoCheckmarkDoneCircleOutline size={25} />
            </Tooltip>
          )}
        </Pressable> */}
      {/* </div> */}
    </Form.Item>
  );
};

export default BookingHistoryAbishekam;
