import { Card, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressReportFormItem from "./address_report_district_form_item";
import AddressReportMandramFormItem from "./address_report_mandram_district_form_item";
import AddressReportSearchButton from "./address_report_search_button";
import { useDispatch } from "react-redux";
import { dynamicClear } from "../../../../../services/redux";
import AddressReportFromDate from "./address_report_from_date";
import AddressReportToDate from "./address_report_to_date";

const AddressReportMainForm = (props) => {
  const {
    address_report,
    set_selected_mandram,
    set_district_name,
    set_selected_date,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [selected_district, set_selected_district] = useState();
  const [value_change, set_value_change] = useState("false");
  const [report_values, set_report_values] = useState({});

  const on_finish = (values) => {
    address_report(report_values);

    // address_report(report_values, set_selected_mandram);
  };

  const on_change_values = (value, values) => {
    set_report_values((previous_values) => ({ ...previous_values, ...values }));

    dispatch(dynamicClear("get_address_report_summary"));
  };

  const on_value_change = () => {};

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Card
        style={{
          width: "97%",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
          backgroundColor: "#e7e7e7",
        }}
      >
        <Form
          onFinish={on_finish}
          id="address_wise_search"
          onValuesChange={on_change_values}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <AddressReportFromDate />
              <AddressReportFormItem
                set_selected_district={set_selected_district}
                set_district_name={set_district_name}
                set_value_change={set_value_change}
              />
            </Col>
            <Col span={12}>
              <AddressReportToDate set_selected_date={set_selected_date} />
              <AddressReportMandramFormItem
                selected_district={selected_district}
                set_selected_mandram={set_selected_mandram}
                value_change={value_change}
              />
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <AddressReportSearchButton />
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddressReportMainForm;
