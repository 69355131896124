import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BookingHistoryAbishekam from "./booking_history_abishekam";
import BookingHistoryDistrict from "./booking_history_district";
import BookingHistoryMandram from "./booking_history_mandram";
import BookingHistorySubmit from "./booking_history_submit";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import {
  dynamicClear,
  dynamicRequest,
  query_get_booking_summary_dashboard,
  query_get_date_district_mandram_wise_summary,
  query_get_mandram_list,
  query_get_mandram_wise_summary,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../services/redux/selector";
import BookingHistoryCount from "../booking_history_count";
import BookingHistoryCollapse from "../booking_history_modal";
import { retrieveItem } from "../../../../helpers/functions";
import { useLocation } from "react-router-dom";

const BookingHistoryForm = (props) => {
  const {
    set_mandram_id,
    initial_values,
    set_initial_values,
    set_booking_detail,
    mandram_id,
    table_open,
    booking_detail,
    search_abishekam_date,
    system_user,
    set_table_open,
    set_mandram_item,
    mandram_item,
    selected_options,
    set_selected_options,
    set_district_id,
    district_id,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const [selectAll, setSelectAll] = useState(false);
  const [form_values, set_form_values] = useState({});
  const [selected_date, set_selected_date] = useState();
  const [selected_last_least_date, set_selected_last_least_date] = useState();
  const [calculated_grand_total_values, set_calculated_grand_total_values] =
    useState({});

  const location = useLocation();
  const { items: districts } = useDynamicSelector("district_list");
  const { items: mandram_items, error: mandram_error } = useDynamicSelector(
    "get_mandram_details"
  );
  console.log("mandram_items", mandram_items);
  const { items: booking_summary } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );

  const { items: booking_mandram_summary } = useDynamicSelector(
    "get_mandram_wise_summary"
  );

  useEffect(() => {
    get_district_list();
  }, []);

  useEffect(() => {
    if (initial_values.code) {
      form.setFieldsValue({
        ...initial_values,
      });
    } else {
      form.resetFields();
    }
  }, [initial_values]);

  useEffect(() => {
    if (district_id) {
      get_mandram_list();
    }
    if (role === "District Admin" || role === "District User") {
      get_mandram_list();
    }
  }, [district_id]);

  const get_date_district_mandram_wise_summary = () => {
    let key = [
      { key: "get_date_district_mandram_wise_summary", loading: true },
    ];
    let query = query_get_date_district_mandram_wise_summary;
    let variables = {
      filter: {
        ...form_values,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const booking_summary_dashboard_count = () => {
    let key = [{ key: "get_booking_summary_dashboard", loading: true }];
    let query = query_get_booking_summary_dashboard;
    let district_id_lists = [district_id];
    let variables = {
      filter: {
        ...form_values,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_mandram_list = () => {
    let key = [{ key: "get_mandram_details", loading: true }];
    let query = query_get_mandram_list;
    let variables = {
      filter: {
        district_id: form_values?.district_id_list || "",
      },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_finish = (values) => {
    // booking_summary_dashboard_count();
    get_date_district_mandram_wise_summary();
    set_booking_detail(values);
  };

  const handle_change = (val, values) => {
    set_form_values(values);
    dispatch(dynamicClear("get_mandram_wise_summary"));
  };

  useEffect(() => {
    if (form_values?.date_slot_id_list && location?.props_state?.id) {
      console.log("calling");
      get_date_district_mandram_wise_summary();
    }
  }, [form_values]);

  useEffect(() => {
    if (location?.props_state?.id) {
      set_form_values({ date_slot_id_list: location?.props_state?.id });
      form.setFieldsValue({ date_slot_id_list: location?.props_state?.id });
    }
  }, [location]);

  return (
    <div style={{ marginTop: "20px", width: "100%" }}>
      <Form
        name="basic"
        onFinish={handle_finish}
        style={{ width: "100%" }}
        layout="vertical"
        form={form}
        onValuesChange={handle_change}
      >
        <div className="booking-history-form">
          <BookingHistoryAbishekam
            search_abishekam_date={search_abishekam_date}
            selected_options={selected_options}
            set_selected_options={set_selected_options}
            form={form}
            set_selected_date={set_selected_date}
            set_selected_last_least_date={set_selected_last_least_date}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
          />
          {role === "District Admin" || role === "District User" ? (
            ""
          ) : (
            <BookingHistoryDistrict
              set_district_id={set_district_id}
              districts={districts}
              form={form}
            />
          )}
          <BookingHistoryMandram
            form={form}
            mandram_items={mandram_items}
            set_mandram_item={set_mandram_item}
            set_initial_values={set_initial_values}
            set_mandram_id={set_mandram_id}
          />
          <BookingHistorySubmit
            form={form}
            search_abishekam_date={search_abishekam_date}
            on_finish={handle_finish}
            set_table_open={set_table_open}
          />
        </div>
      </Form>
      <BookingHistoryCount
        calculated_grand_total_values={calculated_grand_total_values}
      />

      <div>
        <BookingHistoryCollapse
          form={form}
          set_calculated_grand_total_values={set_calculated_grand_total_values}
          table_open={table_open}
          role={role}
          mandram_item={mandram_item}
        />
      </div>
    </div>
  );
};

export default BookingHistoryForm;
