import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  delete_system_ui_module_component_mutation,
  delete_system_ui_module_page,
  get_system_role_list_query,
  get_system_ui_module_list_query,
} from "@services/redux/slices/graphql/graphql_access_rights";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageDeleteModal from "./page_delete_modal";
import { showToast } from "@helpers/functions";
import UIModulePages from "./pages/page";
import RoleItem from "./pages/page_role_item";
import PageComponentRoleCheckBox from "./pages/page_component_role_check_box";
import UIModulePage from "./pages/module_page";
import PageUiComponentRoleCheckBox from "./pages/ui_component_role_check_box";

const PageList = (props) => {
  const dispatch = useDispatch();

  const { items: system_role_list_items, get_system_role_list_loading } =
    useDynamicSelector("get_system_role_list");
  const { items: page_list, loading: get_system_ui_module_list_loading } =
    useDynamicSelector("get_system_ui_module_list");

  const {
    status: delete_status,
    loading: delete_loading,
    error: delete_error,
  } = useDynamicSelector("delete_system_ui_module");

  const {
    status: delete_page_status,
    loading: delete_page_loading,
    error: delete_page_error,
  } = useDynamicSelector("delete_system_ui_module_component");

  const {
    set_open_modal,
    set_action_type,
    set_filed_id,
    set_action_item,
    get_id,
    set_link_open_modal,
    set_expanded_row_key,
    expanded_row_key,
    set_link_type,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [ui_component_list, set_ui_component_list] = useState("");
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const [delete_name, set_delete_name] = useState("");
  const [delete_id, set_delete_id] = useState("");
  const [action_delete, set_action_delete] = useState(null);
  //   const [expanded_row_key, set_expanded_row_key] = useState(null);
  const [page_items, set_page_items] = useState([]);

  const handle_add = (record) => {
    set_open_modal(true);
    set_action_type("add_ui_component");
    set_filed_id(record?.id);
    set_action_item({});
  };

  const handle_link = (record) => {
    set_link_open_modal(true);
    set_link_type("link_component");
    set_action_type("");
    set_filed_id(record);
    set_action_item(record);
  };

  const handle_edit = (record) => {
    set_open_modal(true);
    set_action_type("edit_page");
    set_filed_id(record?.id);
    set_action_item(record);
  };

  const handle_edit_component = (record) => {
    set_open_modal(true);
    set_action_type("edit_page_component");
    set_filed_id(record?.id);
    set_link_type("");
    set_action_item(record);
  };

  const handle_delete = (record) => {
    let delete_id = record?.id;
    set_delete_modal_open(true);
    set_action_delete("delete_page");
    set_delete_name(record);
    set_delete_id(delete_id);
  };

  const handle_delete_component = (record) => {
    let delete_id = record?.id;
    set_delete_modal_open(true);
    set_action_delete("delete_page_component");
    set_delete_name(record);
    set_delete_id(delete_id);
  };

  const handle_delete_close = () => {
    set_delete_modal_open(false);
  };

  const delete_system_ui_module = (id) => {
    let key = [{ key: "delete_system_ui_module", loading: true }];
    let query = delete_system_ui_module_page;
    let variable = {
      id: id,
      data: { id: delete_name?.id, name: delete_name?.name },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const delete_system_ui_module_component = (id) => {
    let key = [{ key: "delete_system_ui_module_component", loading: true }];
    let query = delete_system_ui_module_component_mutation;
    let variable = {
      id: id,
      data: {
        id: delete_name?.id,
        name: delete_name?.name,
        ui_module_id: get_id,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    if (delete_status === "success") {
      showToast({
        type: "success",
        message: "Page deleted successfully",
      });
      system_ui_module_list();
      handle_delete_close();
      dispatch(dynamicClear("delete_system_ui_module"));
    } else if (delete_error === "error") {
      showToast({
        type: "error",
        message: delete_error.message,
      });
      system_ui_module_list();
      dispatch(dynamicClear("delete_system_ui_module"));
    } else if (delete_page_status === "success") {
      showToast({
        type: "success",
        message: "Page component deleted successfully",
      });
      system_ui_module_list();
      handle_delete_close();
      set_expanded_row_key(null);
      dispatch(dynamicClear("delete_system_ui_module_component"));
    } else if (delete_page_error === "error") {
      showToast({
        type: "error",
        message: delete_page_error.message,
      });
      system_ui_module_list();
      set_expanded_row_key(null);
      dispatch(dynamicClear("delete_system_ui_module_component"));
    }
  }, [delete_status, delete_error, delete_page_status, delete_page_error]);
  const on_delete = () => {
    if (action_delete === "delete_page") {
      delete_system_ui_module(delete_id);
    } else {
      delete_system_ui_module_component(delete_id);
    }
  };
  const system_role_list = (values) => {
    let key = [{ key: "get_system_role_list", loading: true }];
    let query = get_system_role_list_query;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const system_ui_module_list = () => {
    let key = [{ key: "get_system_ui_module_list", loading: true }];
    let query = get_system_ui_module_list_query;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    system_role_list();
    system_ui_module_list();
  }, []);

  useEffect(() => {
    set_page_items(page_list);
  }, [page_list]);

  const handleExpand = (record) => {
    if (expanded_row_key === record.key) {
      set_expanded_row_key(null);
    } else {
      set_expanded_row_key(record.key);
    }
  };

  // Calculate the total width required for all columns in both tables
  // const totalColumnWidth =
  //   200 + // Width of the first column
  //   system_role_list_items?.length * 150; // Adjust the width based on the number of columns

  const columns = [
    {
      title: "Pages",
      dataIndex: "",
      key: "",
      fixed: "left",
      width: 150,
      render: (page, record, page_index) => (
        <UIModulePages
          key={page_index}
          handle_add={handle_add}
          handle_edit={handle_edit}
          handle_delete={handle_delete}
          setIsHovered={setIsHovered}
          expanded_row_key={expanded_row_key}
          record={page}
        />
      ),
    },
    ...(system_role_list_items?.map((role, role_index) => ({
      title: <RoleItem key={role_index} item={role} />,
      dataIndex: "customDataIndex",
      key: `role_${role_index}`,
      render: (text, record, index) => (
        <PageUiComponentRoleCheckBox
          page_component={record}
          role={role}
          key={index}
        />
      ),
    })) || []),
  ];

  const expanded_rowRender = (props) => {
    const columns = [
      {
        title: "Pages",
        dataIndex: "",
        key: "",
        fixed: "left",
        width: 150,
        render: (page, record, page_index) => (
          <UIModulePage
            key={page_index}
            handle_link={handle_link}
            handle_edit={handle_edit_component}
            handle_delete={handle_delete_component}
            setIsHovered={setIsHovered}
            isHovered={isHovered}
            expanded_row_key={expanded_row_key}
            record={page}
          />
        ),
      },
      ...(system_role_list_items?.map((role, role_index) => ({
        title: <RoleItem key={role_index} item={role} />,
        dataIndex: "customDataIndex",
        key: `role_${role_index}`,
        render: (text, record, index) => (
          <PageComponentRoleCheckBox
            page_component={record}
            role={role}
            key={index}
          />
        ),
      })) || []),
    ];
    const nestedTableData = ui_component_list;

    return (
      <Table
        style={{ height: "100%" }}
        rowKey={(record) => record.key}
        dataSource={nestedTableData}
        pagination={false}
        onHeaderRow={() => ({
          style: { display: "none" },
        })}
        columns={columns}
        className="table-striped-rows_expand"
      />
    );
  };

  return (
    <div
      className="custom-table"
      style={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Table
        loading={get_system_ui_module_list_loading}
        sticky={{
          offsetHeader: 0,
        }}
        dataSource={page_items?.map((item, index) => ({
          ...item,
          key: index,
        }))}
        expandedRowRender={expanded_rowRender}
        onExpand={(expanded, record) => {
          if (expanded) {
            set_ui_component_list(record.ui_module_components);
            system_ui_module_list();
          } else {
            set_ui_component_list([]);
            system_ui_module_list();
          }
          handleExpand(record);
        }}
        expandedRowKeys={expanded_row_key !== null ? [expanded_row_key] : []}
        pagination={false}
        className="table-striped-rows"
        scroll={{
          x: true,
          y: 400,
        }}
        columns={columns}
      />
      <PageDeleteModal
        delete_modal_open={delete_modal_open}
        handle_delete_close={handle_delete_close}
        delete_name={delete_name}
        on_delete={on_delete}
        delete_loading={delete_loading}
        delete_page_loading={delete_page_loading}
        action_delete={action_delete}
      />
    </div>
  );
};

export default PageList;
