import React, { useEffect, useState } from "react";
import BookingHistoryCount from "../components/booking_history/booking_history_count";
import { Card, Table, Typography } from "antd";
import BookingHistoryForm from "../components/booking_history/booking_history_form/booking_history_form";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../services/redux/selector";
import { useDispatch } from "react-redux";
import { dynamicRequest } from "../../services/redux/slices";
import { district_details_query } from "../../services/redux/slices/graphql/graphql_district";
import BookingHistoryCollapse from "../components/booking_history/booking_history_modal";
import { retrieveItem } from "../../helpers/functions";
import ReportMainForm from "./reports";
import CustomPageTitle from "../components/common/custom_page_title";
import LoaderSpinner from "../components/common/custom_spinner";

const DateWiseReports = () => {
  const { t } = useTranslation();
  const [table_open, set_table_open] = useState(false);
  const [mandram_id, set_mandram_id] = useState();
  const [initial_values, set_initial_values] = useState({});
  const [booking_detail, set_booking_detail] = useState();
  const dispatch = useDispatch();

  const item = useDynamicSelector("get_booking_detail");
  const { items: booking_summary, loading: booking_summary_loading } =
    useDynamicSelector("get_date_district_mandram_wise_summary");
  const role = retrieveItem("role");

  useEffect(() => {
    get_district_list();
  }, []);

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      <CustomPageTitle title={t("date_wise_reports_title")} />
      <ReportMainForm
        set_table_open={set_table_open}
        set_mandram_id={set_mandram_id}
        initial_values={initial_values}
        set_initial_values={set_initial_values}
        set_booking_detail={set_booking_detail}
        mandram_id={mandram_id}
        role={role}
        booking_detail={booking_detail}
        table_open={table_open}
      />
    </>
  );
};
export default DateWiseReports;
