import { Box, Center, HStack, Image, Text } from "native-base";
import React from "react";

const LoginHeaderBar = () => {
  return (
    <div
      style={{
        background: "linear-gradient(to right, #FACF61, #EC9501)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center ",
        height: "25%",
      }}
    >
      <div style={{}}>
        <Image
          source={require("../../../assets/icons/Masm_logo.png")}
          alt="Adhiparasakthi Image"
          size={79}
        />
      </div>
      <div>
        <Text
          style={{
            display: "flex",
            justifyContent: "center",

            fontSize: "20px",
            fontWeight: "bold",
            color: "#FE301D",
          }}
        >
          ADHIPARASAKTHI SIDDHAR PEEDAM
        </Text>
        <Text
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            fontSize: "13px",
            width: "%",
            color: "#313131",
          }}
        >
          Melmaruvathur,Kanchipuram District,Tamilnadu,
          <Text
            style={{
              marginTop: "5px",
              color: "#313131",
              fontSize: "13px",
              marginRight: "15px",
            }}
          >
            India-603319
          </Text>
        </Text>
      </div>
    </div>
  );
};

export default LoginHeaderBar;
