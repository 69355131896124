import { Form, Select, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const BookingHistoryDistrict = ({
  form,
  districts,
  set_district_id,
  select_district_option,
  set_select_district_option,
}) => {
  const { t } = useTranslation();
  const district_list = districts ? [...districts] : null;

  // Sort district names in ascending order
  district_list?.sort((a, b) => a?.name?.localeCompare(b?.name));
  const handle_change = (value) => {
    set_district_id(value);
  };

  return (
    <Form.Item
      label={t("district")}
      name="district_id_list"
      style={{ width: "250px" }}
    >
      <Select
        className="district-place-holder"
        style={{
          width: "100%",
        }}
        placeholder={<Typography> All Districts</Typography>}
        onChange={handle_change}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {district_list?.map((district) => (
          <Select.Option key={district.id} value={district.id}>
            {district.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BookingHistoryDistrict;
