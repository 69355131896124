import { Card, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import BookingActionForm from "../components/booking_action/booking_action_form/booking_action_form";
import CustomPageTitle from "../components/common/custom_page_title";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { useTranslation } from "react-i18next";
import BookingHistoryList from "../components/booking_history/booking_history_list";
import BookingActionList from "../components/booking_action/booking_action_list/booking_action_list_page";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_booking_action_list,
  query_check_has_pos,
  useDynamicSelector,
} from "../../services/redux";

const BookingAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: booking_action_list, loading: booking_list_loading } =
    useDynamicSelector("get_action_list");
  // useEffect(() => {
  //   mandram_wise_booking_list();
  // });

  const [has_pos, set_has_pos] = useState(false);
  console.log("has_pos", has_pos);

  const result = useDynamicSelector("check_has_pos");

  const check_has_pos_query = () => {
    let key = [{ key: "check_has_pos", loading: true }];
    let query = query_check_has_pos;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    check_has_pos_query();
  }, []);

  useEffect(() => {
    set_has_pos(result);
  }, [result]);

  const mandram_wise_booking_list = (values) => {
    let key = [{ key: "get_action_list", loading: true }];
    let query = query_booking_action_list;
    let variables = {
      filter: {
        date_slot_id: values?.date,
        booking_reference: values?.receipt_no,
      },
    };
    console.log("variables", variables);
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("booking_action")} />
        </Row>
      </Row>

      <Card
        style={{
          width: "98%",
          margin: "5px auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <BookingActionForm
          mandram_wise_booking_list={mandram_wise_booking_list}
        />
        <BookingActionList
          mandram_wise_booking_list={mandram_wise_booking_list}
          has_pos={has_pos}
          // updating_booking_payment_list={updating_booking_payment_list}
        />
      </Card>
    </div>
  );
};

export default BookingAction;
