import React, { useEffect, useState } from "react";
import { Progress, Skeleton } from "antd";
import "antd/dist/antd.css";
import {
  get_calendar_progressbar_color,
  get_progress_bar_filed_color,
  get_progress_bar_un_filed_color,
} from "../../../../helpers/functions";
import { useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";

const ProgressBar = ({ calendar_data, date_array, index }) => {
  // const [date_data, set_date_data] = useState({});

  const {
    items: dashboard_calendar_items,
    loading: dashboard_calendar_loading,
  } = useDynamicSelector("get_dashboard_calendar");

  const dispatch = useDispatch();

  const booked_percentage =
    dashboard_calendar_items &&
    get_calendar_progressbar_color(
      calendar_data?.waiting_quota,
      calendar_data?.confirmed_quota,
      calendar_data?.count?.booked
    );

  return (
    <Progress
      style={{ width: "100%" }}
      showInfo={false}
      status="active"
      percent={booked_percentage}
      strokeColor={get_progress_bar_filed_color(
        calendar_data?.date_slot?.availability_color
      )}
      trailColor={get_progress_bar_un_filed_color(
        calendar_data?.date_slot?.availability_color
      )}
    />
  );
};

export default ProgressBar;
