import { Form } from "antd";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import IrumudiPortalUsernameFormItem from "./irumudi_portal_username";
import IrumudiPortalPasswordFormItem from "./irumudi_portal_password_form_item";
import IrumudiGetReceiptButton from "./irumudi_portal_get_receipt_button";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_get_booking_receipt,
  useDynamicSelector,
} from "../../../../../services/redux";
import { toast } from "react-toastify";
import { navigate, navigateWithProps } from "../../../../../helpers/functions";

const IrumudiPortalForm = () => {
  const dispatch = useDispatch();
  const [check_mobile_number, set_check_mobile_number] = useState("");
  const {
    booking_user: booking_receipt_user,
    booking_status: booking_receipt_status,
    error: booking_receipt_error,
    booking_reference: booking_receipt_reference,
    booking_user_id: booking_receipt_user_id,
  } = useDynamicSelector("get_booking_receipt");
  console.log("booking_receipt_user?.mobile", booking_receipt_user?.mobile);
  useEffect(() => {
    if (booking_receipt_user_id) {
      // navigate(`${ROUTES.PRINT_RECEIPT}/${booking_receipt_reference}`);
      // navigate(`/print-receipt/${booking_receipt_reference}`);
      // navigateWithProps(`/print-receipt/${booking_receipt_reference}`, {
      //   booking_reference: booking_receipt_reference,
      //   booking_mobile_number: booking_receipt_user?.mobile,
      // });
      const url = `/print-receipt/${booking_receipt_reference}/${booking_receipt_user?.mobile}`;

      const new_tab = window.open(url, "_blank", "width=850, height=900");
      if (new_tab) {
        new_tab.focus();
      }

      // dispatch(dynamicClear("get_booking_receipt"));
    } else if (booking_receipt_error) {
      toast.error("No receipt found");
      dispatch(dynamicClear("get_booking_receipt"));
    } else if (check_mobile_number.length !== 10) {
      // toast.error("Mobile number should be 10 digits");
      dispatch(dynamicClear("get_booking_receipt"));
    }
  }, [
    booking_receipt_status,
    booking_receipt_error,
    booking_receipt_user_id,
    check_mobile_number,
  ]);

  const get_receipt = (values) => {
    console.log("values", values);
    let key = [{ key: "get_booking_receipt", loading: true }];
    let query = query_get_booking_receipt;
    let variables = {
      custom: {
        booking_reference: values.booking_reference,
        booking_user_mobile: values.booking_user_mobile,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_finish = (values) => {
    if (!navigator.onLine) {
      toast.error("Something went wrong please try again");
      return;
    }
    if (check_mobile_number.trim().length === 0) {
      toast.error("Please enter a valid mobile number.");
    } else if (check_mobile_number.trim().length !== 10) {
      toast.error("Mobile number should be 10 digits.");
    } else {
      get_receipt(values);
    }
  };

  // useEffect(() => {
  //   dispatch(dynamicClear("get_booking_receipt"));
  // }, []);
  return (
    <div>
      <Form id="receipt_form" onFinish={on_finish}>
        <IrumudiPortalUsernameFormItem />
        <IrumudiPortalPasswordFormItem
          set_check_mobile_number={set_check_mobile_number}
        />
        <IrumudiGetReceiptButton />
      </Form>
    </div>
  );
};

export default IrumudiPortalForm;
