import React, { useState } from "react";
import { Col, Row, Typography } from "antd";
import SubUserTable from "./sub_user_table";
import { useTranslation } from "react-i18next";
import CustomButton from "../../common/custom_button";
import AdminCard from "./admin_card";
import EditAdminModal from "../district_modal/edit_admin_modal";
import { IoMdAddCircleOutline } from "react-icons/io";
import { retrieveItem } from "../../../../helpers/functions";
const { Title } = Typography;

const ExpandableDistrictForm = (props) => {
  const {
    color,
    get_one_district,
    get_all_districts,
    get_all_districts_users,
    record_id,
    items,
    add_user_modal_visible,
    set_add_user_modal_visible,
    expandable_district_index,
    record,
  } = props;
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const [header_type, set_header_type] = useState("");
  const [edit_admin_modal_open, set_edit_admin_modal_open] = useState(false);

  const add_user = () => {
    set_header_type("add_user");
    set_add_user_modal_visible(true);
  };

  return (
    <Row
      data-color={color === "#ffeadd" ? "lightgold" : "white"}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        borderRadius: "10px",
        height: "auto",
      }}
    >
      <Row
        style={{
          margin: "auto",
          width: "100%",
        }}
      >
        <Col
          style={{
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            marginBottom: "5px",
            width: "100%",
            padding: "3px",
          }}
        >
          <Title level={5} style={{ color: "black" }}>
            {t("admin")}
          </Title>
          <AdminCard
            items={items}
            set_open={set_edit_admin_modal_open}
            set_header_type={set_header_type}
          />
        </Col>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            margin: "10px 0px",
          }}
        >
          <Title level={5} style={{ color: "black" }}>
            {t("district_user")}
          </Title>
          {role === "District Admin" && (
            <CustomButton
              button_name={t("add")}
              onclick_button={add_user}
              icon={<IoMdAddCircleOutline />}
            />
          )}
        </Row>

        <SubUserTable
          color={color}
          get_one_district={get_one_district}
          get_all_districts={get_all_districts}
          get_all_districts_users={get_all_districts_users}
          record_id={record_id}
          items={items}
          add_user_modal_visible={add_user_modal_visible}
          set_add_user_modal_visible={set_add_user_modal_visible}
          expandable_district_index={expandable_district_index}
          header_type={header_type}
          set_header_type={set_header_type}
        />
      </Row>

      <EditAdminModal
        get_all_districts={get_all_districts}
        record={record}
        open={edit_admin_modal_open}
        set_open={set_edit_admin_modal_open}
      />
    </Row>
  );
};

export default ExpandableDistrictForm;
