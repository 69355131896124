import { Form, Input } from "antd";
import { Box } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../../../src/index.css";

const BookingUsernameFormItem = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: t("username_required"),
          },
          {
            max: 30,
            message: "Username must be at most 30 characters",
          },
        ]}
      >
        <Input
          placeholder="Username"
          className="placeholder-black"
          step="any"
          style={{
            width: "300px",
            border: "1px solid black",
            backgroundColor: "#ffde8b !important",
          }}
        />
      </Form.Item>
    </div>
  );
};

export default BookingUsernameFormItem;
