import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Count from "./booking_history_counts";
import {
  dynamicRequest,
  query_get_booking_summary_dashboard,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";

const BookingHistoryCount = ({ calculated_grand_total_values }) => {
  const [yet_to_enroll, set_yet_to_enroll] = useState(0);
  const booking_summary_dashboard = useDynamicSelector(
    "get_booking_summary_dashboard"
  );

  const { loading: booking_summary_loading } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );

  console.log("calculated_grand_total_values", calculated_grand_total_values);

  const formatNumberWithCommas = (number) => {
    return number?.toLocaleString("en-IN");
  };

  return (
    <div>
      <Row
        style={{
          width: "100%",
          height: "100px",
          alignItems: "center",
        }}
      >
        <Count
          label={"TOTAL BOOKED"}
          value={formatNumberWithCommas(
            calculated_grand_total_values?.sum_total_booked || 0
          )}
          backgroundColor={"#E84258"}
        />
        <Count
          label={"TOTAL ENROLLED"}
          value={formatNumberWithCommas(
            calculated_grand_total_values?.sum_total_enrolled || 0
          )}
          backgroundColor={"#FD8060"}
        />
        <Count
          label={"TOTAL YET TO ENROLL"}
          value={formatNumberWithCommas(
            calculated_grand_total_values?.sum_total_yet_to_enroll || 0
          )}
          backgroundColor={"#FFA500"}
        />

        <Count
          label={"TOTAL CANCELLED"}
          value={formatNumberWithCommas(
            calculated_grand_total_values?.sum_total_cancelled || 0
          )}
          hide_border={true}
          backgroundColor={"#8281A0"}
        />
        <Count
          label={"TOTAL PREPAID"}
          value={formatNumberWithCommas(
            calculated_grand_total_values?.sum_total_prepaid_amount || 0
          )}
          backgroundColor={"#808000"}
        />
      </Row>
    </div>
  );
};

export default BookingHistoryCount;
